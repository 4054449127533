import React, {useEffect, useContext, useState} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiButton from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { TemplateOneContext } from './TemplateOneContext';
import Swal from 'sweetalert2';
import { uploadImage } from '../../components/ImageUploader';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50', // Green color
    },
  },
});

export default function UpdateForm({ formData, setFormData }) {
   const { UpdateTemp1Instance, setLoading, instance, instances} = useContext(TemplateOneContext)
   const { instanceId } = useParams()
   const [subjectExists, setSubjectExists] = useState(false); // State for subject existence
 
   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setFormData({ ...formData, image: file, image_url: url });
    }
  };

  // Real-time validation for subject
  useEffect(() => {
   if (formData.subject) {
     const exists = instances.some(i => i.subject.toLowerCase() === formData.subject.toLowerCase() && i.id !== instance.id);
     setSubjectExists(exists);
   } else {
     setSubjectExists(false);
   }
 }, [formData.subject, instances, instance]);

 const handleSubmit = async (e) => {
   e.preventDefault(); // Prevent the default form submission behavior
   if (checkEquality(formData,instance)){
    Swal.fire({
      position: "top-end",
      icon: "warning",
      text: `No Changes detected.`,
      showConfirmButton: false,
      timer: 5000,
      toast: true,
      color: 'black',
      background: 'orange'
   });
   return
  }

   const updatedData = formData
   

   if (formData.image instanceof File) {
     try {
       const result = await uploadImage(formData.image);
       updatedData.image = result.url; 
       updatedData.image_id = result.id; 
     } catch (error) {
       console.error("Error uploading image:", error);
       Swal.fire({
         position: "top-end",
         icon: "warning",
         text: error || "An error occurred during image upload.",
         showConfirmButton: false,
         timer: 1500,
         toast: true,
         color: 'wheat',
         background: 'red'
       });
       return; // Terminate the function if there is an error
     }
   } else {
     updatedData.image = formData.image;
     updatedData.image_id = formData.image_id;
   }

   try {
     await UpdateTemp1Instance(instanceId, updatedData); // Send JSON data
     setLoading(true);
   } catch (error) {
     console.error('Error updating instance:', error);
   }
 };


 function checkEquality(obj1, obj2) {
  // Check if both are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
  }

  // Get keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
      return false;
  }

  // Check if all keys and values are the same
  for (let key of keys1) {
      if (!keys2.includes(key) || obj1[key] !== obj2[key]) {
          return false;
      }
  }

  return true;
}

function handleDiscard(){

Swal.fire({
  title: "Are you sure?",
  text: "Note: Your changes will be discarded! Would you like to continue?",
  icon: "warning",
  color:'black',
  toast:true,
  showCancelButton: true,
  confirmButtonColor: "green",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, continue",
  background:'orange' // change the colorcode 
}).then((result) => {
  if (result.isConfirmed) {
    setFormData(instance)
  }
});
}

function navigateBack(){
if(!checkEquality(formData,instance)){
  Swal.fire({
    title: "Are you sure?",
    text: "Note: Your changes will be Discarded! Would you like to continue?",
    icon: "warning",
    color:'black',
    toast:true,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Continue",
    background:'rgb(255, 0, 0,0.8)' // change the colorcode 
  }).then((result) => {
    if (result.isConfirmed) {
      setFormData(instance)
      window.history.back()
    }
  });
}else{
  window.history.back()
}
}
function handleDiscardColors(){
Swal.fire({
  title: "Are you sure?",
  text: "Note: Your color styles will be reverted to the original ones! Would you like to continue?",
  icon: "warning",
  color:'black',
  toast:true,
  showCancelButton: true,
  confirmButtonColor: "green",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, continue",
  background:'orange' // change the colorcode 
}).then((result) => {
  if (result.isConfirmed) {
    discardColorStyles()
  }
});
}
function discardColorStyles() {
const initialColors = {
  "body_bg_color": "#98FF98",
  "btn_bg_color": "#32CD32",
  "btn_border_color": "#808000",
  "btn_text_color": "#808000",
  "footer_bd_top_color": "#FFD700",
  "footer_bg_color": "#333333",
  "footer_link_color": "#28a745",
  "footer_text_color": "#FFFFFF",
  "header_bd_btm_color": "#333333",
  "header_bg_color": "#32CD32",
  "text_color": "#000000",
  "title_color": "#333333",
  "view_more_link_color": "#0000FF"
};

setFormData(prevFormData => ({
  ...prevFormData, // Retain existing keys and values
  ...initialColors, // Update with initialColors
}));
// console.log(formData)
}


  return (
    <ThemeProvider theme={theme}>
      <div  className="container p-4 border rounded bg-light shadow-sm">
        
        <form onSubmit={handleSubmit}>

          {/* Subject */}
          <div className="mb-3">
            <TextField
              label="Subject"
              variant="outlined"
              fullWidth
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="mb-2"
            />
            {subjectExists && (
              <div className='text-danger'><small>Subject already Exists!</small></div>
            )}
          </div>

          <div className="accordion" id="accordionPanelsStayOpen">
            {/* head section */}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                  Head Section
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                <div className="accordion-body">
                  
                <div className="mb-3">
                    <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                    <input
                    type="text" 
                    className="form-control" 
                    id='title'
                    name='title'
                    value={formData?.title}
                    onChange={handleChange}
                    style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                      />
                  </div>
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button bg-success bg-opacity-50 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                        colors
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                      <div className="accordion-body">
                        <div className='d-flex flex-wrap justify-content-around'>
                          
                            <div className="mb-3">
                                <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Background</label>
                                <input
                                  id='header_bg_color'
                                  name='header_bg_color'
                                type="color" 
                                value={formData?.header_bg_color}
                                onChange={handleChange}
                                className="form-control form-control-color"
                                style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                  />

                              </div>
                            <div className="mb-3">
                                <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                                <input
                                  id='title_color'
                                  name='title_color'
                                type="color" 
                                value={formData?.title_color}
                                onChange={handleChange}
                                className="form-control form-control-color"
                                style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                  />

                              </div>
                            <div className="mb-3">
                                <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>border bottom</label>
                                <input
                                  id='header_bd_btm_color'
                                  name='header_bd_btm_color'
                                type="color" 
                                value={formData?.header_bd_btm_color}
                                onChange={handleChange}
                                className="form-control form-control-color"
                                style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                  />

                              </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* Body Section */}
            <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                    Body Section
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                  <div className="accordion-body">

                    {/* main background color */}
                    <div className="mb-3">
                      <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Background Color</label>
                      <input
                        id='body_bg_color'
                        name='body_bg_color'
                      type="color" 
                      value={formData?.body_bg_color}
                      onChange={handleChange}
                      className="form-control form-control-color"
                      style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                        />

                    </div>


                    {/* Main Section */}
                    <div className="accordion-item">
                      <h4 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#section1" aria-expanded="true" aria-controls="section1">
                        Main Section 
                        </button>
                      </h4>
                      <div id="section1" className="accordion-collapse collapse show">
                        <div className="accordion-body">
                          
                          <div className="mb-3">
                            <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Image</label>
                            <input
                            id='image'
                            name='image'
                              type="file"
                              accept=".png, .jpg, .jpeg, .gif"
                              onChange={handleFileChange}
                              className="form-control"
                            
                            style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                              />
                          </div>

                          <div className="mb-3">
                            <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                            <textarea 
                            id='text'
                            name='text'
                            value={formData?.text}
                            onChange={handleChange}
                            className="form-control" 
                            style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                            ></textarea>
                          </div>

                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>View More Link</label>
                              <input
                              type="url" 
                              className="form-control" 
                              id='view_more_link'
                              name='view_more_link'
                              value={formData?.view_more_link}
                              onChange={handleChange}
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />
                            </div>


                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button className="accordion-button bg-success bg-opacity-50 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#section1-colors" aria-expanded="false" aria-controls="section1-colors">
                                colors
                              </button>
                            </h2>
                            <div id="section1-colors" className="accordion-collapse collapse">
                              <div className="accordion-body">
                                <div className='d-flex flex-wrap justify-content-around'>
                                  
                                    <div className="mb-3">
                                        <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>text</label>
                                        <input
                                          id='text_color'
                                          name='text_color'
                                        type="color" 
                                        value={formData?.text_color}
                                        onChange={handleChange}
                                        className="form-control form-control-color"
                                        style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                          />

                                      </div>
                                    <div className="mb-3">
                                        <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>link</label>
                                        <input
                                          id='view_more_link_color'
                                          name='view_more_link_color'
                                        type="color" 
                                        value={formData?.view_more_link_color}
                                        onChange={handleChange}
                                        className="form-control form-control-color"
                                        style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                          />

                                      </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    {/* buttons */}
                    <div className="accordion-item">
                      <h4 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#buttonsSection" aria-expanded="true" aria-controls="buttonsSection">
                        Button Section
                        </button>
                      </h4>
                      <div id="buttonsSection" className="accordion-collapse collapse show">
                        <div className="accordion-body">
                          
                        <div className="mb-3">
                            <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Website btn</label>
                            <input
                            type="text" 
                            className="form-control" 
                            id='website'
                            name='website'
                            value={process.env.REACT_APP_WEBSITE_LINK}
                            // onChange={handleChange}
                            disabled
                            style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                              />
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button className="accordion-button bg-success bg-opacity-50 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBtnColors" aria-expanded="false" aria-controls="collapseBtnColors">
                                colors
                              </button>
                            </h2>
                            <div id="collapseBtnColors" className="accordion-collapse collapse">
                              <div className="accordion-body">
                                <div className='d-flex flex-wrap justify-content-around'>
                                  
                                    <div className="mb-3">
                                        <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Background</label>
                                        <input
                                          id='btn_bg_color'
                                          name='btn_bg_color'
                                        type="color" 
                                        value={formData?.btn_bg_color}
                                        onChange={handleChange}
                                        className="form-control form-control-color"
                                        style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                          />

                                      </div>
                                    <div className="mb-3">
                                        <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                                        <input
                                          id='btn_text_color'
                                          name='btn_text_color'
                                          type="color" 
                                          value={formData?.btn_text_color}
                                          onChange={handleChange}
                                          className="form-control form-control-color"
                                          style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                          />

                                      </div>
                                    <div className="mb-3">
                                        <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Border</label>
                                        <input
                                          id='btn_border_color'
                                          name='btn_border_color'
                                          type="color" 
                                          value={formData?.btn_border_color}
                                          onChange={handleChange}
                                          className="form-control form-control-color"
                                          style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                          />

                                      </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
            </div>

            {/* footer section  */}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#footer" aria-expanded="true" aria-controls="footer">
                Footer 
                </button>
              </h2>
              <div id="footer" className="accordion-collapse collapse ">
                <div className="accordion-body d-flex flex-wrap justify-content-around">
                  
                <div className="mb-3">
                    <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Border Top</label>
                    <input
                    type="color" 
                    id='footer_bd_top_color'
                    name='footer_bd_top_color'
                    value={formData?.footer_bd_top_color}
                    onChange={handleChange}
                    className="form-control form-control-color"
                    style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                      />
                </div>
                <div className="mb-3">
                    <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Background</label>
                    <input
                    type="color" 
                    id='footer_bg_color'
                    name='footer_bg_color'
                    value={formData?.footer_bg_color}
                    onChange={handleChange}
                    className="form-control form-control-color"
                    style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                      />
                </div>
                <div className="mb-3">
                    <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                    <input
                    type="color" 
                    id='footer_text_color'
                    name='footer_text_color'
                    value={formData?.footer_text_color}
                    onChange={handleChange}
                    className="form-control form-control-color"
                    style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                      />
                </div>
                <div className="mb-3">
                    <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Links</label>
                    <input
                    type="color" 
                    id='footer_link_color'
                    name='footer_link_color'
                    value={formData?.footer_link_color}
                    onChange={handleChange}
                    className="form-control form-control-color"
                    style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                      />
                </div>


                  

                </div>
              </div>
            </div>


          </div>
            
          <hr />

          <div className='my-3 d-flex flex-wrap justify-content-around gap-1'>


              <button 
                type="submit" 
                disabled={subjectExists}
                className='btn btn-success  px-4'
                style={{ cursor: 'pointer' }}>
                Save 
              </button>
              <button type="button" 
                onClick={handleDiscard}
                className='btn btn-warning  px-4'
                style={{ cursor: 'pointer' }}>
                Discard 
              </button>
              <button type="button" 
                onClick={handleDiscardColors}
                className='btn btn-warning  px-4'
                style={{ cursor: 'pointer' }}>
                Reset colors 
              </button>
              <button type="button" 
                onClick={navigateBack}
                className='btn btn-danger  px-4'
                style={{ cursor: 'pointer' }}>
                Back 
              </button>

          </div>

          

        </form>
      </div>
    </ThemeProvider>
  );
}
