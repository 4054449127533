import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { TemplateTwoContext } from './TemplateTwoContext';

export default function TestInstance() {
  const { testTemp2Instance } = useContext(TemplateTwoContext);
  const { instanceId } = useParams();
  const testMail = 'dummyemailplaceholder@example.com'

  function handleSpammynessTest() {
    Swal.fire({
      title: "Input Mail Tester address",
      input: "email",
      inputLabel: "Email",
      inputPlaceholder: "test-djnhtqyow@srv1.mail-tester.com",
      background: 'rgba(8, 50, 32, 0.81)',
      color: '#B79F00',
      confirmButtonText: 'Test',
      showCancelButton: true,
      html: `
        <small style="color:wheat;font-style: italic;">Obtain the Mail Tester address from <a href="https://www.mail-tester.com/" target="_blank" style="color: #;">Mail Tester</a>.</small>
      `,
      inputValidator: (value) => {
        if (!value || !value.endsWith('@srv1.mail-tester.com')) {
          return 'Please enter a valid email from Mail Tester';
        }
      }
    }).then((result) => {
      if (result.value) {
        const email = result.value;
        testTemp2Instance(instanceId,email);
        // You can call your test function here with the email
      }
    });
  }

  return (
    <div className="dropdown">
      <button 
      onClick={() => testTemp2Instance(instanceId,testMail)}
      className="btn btn-success mx-1 " type="button">
        Test
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li>
          <button className="dropdown-item" onClick={() => testTemp2Instance(instanceId,testMail)} >Normal test</button>
        </li>
        <li>
          <button className="dropdown-item" onClick={() => handleSpammynessTest()}>Spammyness Test</button>
        </li>
      </ul>
    </div>
  );
}
