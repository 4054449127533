import React, { useContext, useState } from 'react';
import { LogContext } from '../context/LogContext';
import LoadingComponent from '../components/LoadingComponent';
import LogSummaryDetails from './LogSummaryDetails';
import RefreshLogs from './RefreshLogs';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Alert,
  TablePagination,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import { green } from '@mui/material/colors';

export default function Logs() {
  const { logsSummary, loading, formatToCustomDate,totalRecipients, openRate,clickRate,totalClicks,totalOpens } = useContext(LogContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  if (loading) {
    return (
          <LoadingComponent loadingText={'Loading logs...'}/>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="flex-end">
        <RefreshLogs />
      </Box>

      <Typography variant="h4" align="center" sx={{ marginY: 3, color: green[600], fontWeight: 'bold', fontFamily: 'Junge' }}>
        Tracking & Analytics
      </Typography>

      {/* Analytics Section */}
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={3} md={2}>
            <Card sx={{ backgroundColor: green[50], boxShadow: 3 }}>
              <CardContent>
                <Typography variant="small" sx={{ fontWeight: 'bold', color: green[700] }}>Sent: </Typography>
                <Typography variant="small" sx={{ fontWeight: 'bold' }}>{totalRecipients || 0}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Card sx={{ backgroundColor: green[50], boxShadow: 3 }}>
              <CardContent>
                <Typography variant="small" sx={{ fontWeight: 'bold', color: green[700] }}>Total Opens: </Typography>
                <Typography variant="small" sx={{ fontWeight: 'bold' }}>{totalOpens || 0}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Card sx={{ backgroundColor: green[50], boxShadow: 3 }}>
              <CardContent>
                <Typography variant="small" sx={{ fontWeight: 'bold', color: green[700] }}>Total Clicks: </Typography>
                <Typography variant="small" sx={{ fontWeight: 'bold' }}>{totalClicks || 0}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Card sx={{ backgroundColor: green[50], boxShadow: 3 }}>
              <CardContent>
                <Typography variant="small" sx={{ fontWeight: 'bold', color: green[700] }}>Open Rate: </Typography>
                <Typography variant="small" sx={{ fontWeight: 'bold' }}>{openRate || 0}%</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Card sx={{ backgroundColor: green[50], boxShadow: 3 }}>
              <CardContent>
                <Typography variant="small" sx={{ fontWeight: 'bold', color: green[700] }}>Click Rate: </Typography>
                <Typography variant="small" sx={{ fontWeight: 'bold' }}>{clickRate || 0}%</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Logs Table */}
      <TableContainer component={Paper} sx={{ borderRadius: '12px', boxShadow: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: green[200] }}>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Template</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Subject</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Type</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Recipients</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Opens</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Clicks</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Time</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logsSummary && logsSummary.length > 0 ? (
              logsSummary.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((log, index) => (
                <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                  <TableCell align="center">{log.email_template}</TableCell>
                  <TableCell align="center">{log.subject}</TableCell>
                  <TableCell align="center">{log.email_type}</TableCell>
                  <TableCell align="center">{log.recipients}</TableCell>
                  <TableCell align="center">{log.opens}</TableCell>
                  <TableCell align="center">{log.clicks}</TableCell>
                  <TableCell align="center">{formatToCustomDate(log.time_sent)}</TableCell>
                  <TableCell align="center">
                    <LogSummaryDetails summaryLog={log} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Alert severity="warning" sx={{ width: '100%' }}>
                    No emails sent
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          style={{background:'lightgreen'}}
          component="div"
          count={logsSummary.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
