import React from 'react';
import { MdError } from 'react-icons/md'; // Importing an error icon from React Icons
import {useNavigate} from 'react-router-dom';

const Unauthorized = () => {
  const navigate = useNavigate()
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: 'rgb(255,0,0,0.432)', // Light background
      textAlign: 'center',
    },
    heading: {
      fontSize: '3rem',
      fontWeight: 'bold',
      color: '#dc3545', // Bootstrap danger color
      marginBottom: '1rem',
    },
    subheading: {
      fontSize: '1.5rem',
      color: '#6c757d', // Bootstrap secondary color
      marginBottom: '2rem',
    },
    button: {
      backgroundColor: '#007bff', // Bootstrap primary color
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      fontSize: '1rem',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    buttonHover: {
      backgroundColor: '#0056b3', // Darker shade for hover effect
    },
    icon: {
      fontSize: '4rem',
      color: '#dc3545',
      marginBottom: '1rem',
    },
  };

  return (
    <div style={styles.container} className="container">
      <div className="text-center">
        <MdError style={styles.icon} /> {/* Using React Icons for error */}
        <h1 style={styles.heading}>Unauthorized Access</h1>
        <p style={styles.subheading}>
          You do not have permission to view this page.
        </p>
        <button
          style={styles.button}
          className="btn"
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.button.backgroundColor}
          onClick={() => navigate('/home')} // Redirect to home
        >
          Go Back to Home
        </button>
      </div>
    </div>
  );
};

export default Unauthorized;
