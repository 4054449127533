import React from 'react'
import { FiDollarSign } from 'react-icons/fi';

export default function PlanUsage() {
  return (
    <div style={{ backgroundColor: 'lightgreen', borderRadius: '10px', padding: '20px' }}>
      <h3 style={{ color: 'darkgreen' }}>Plan Usage</h3>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p><strong>Emails Sent This Month:</strong> 5000/10000</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <FiDollarSign size={30} color="green" />
              <p style={{ marginLeft: '10px', fontSize: '1rem' }}>Upgrade your plan to increase email limits <br/>and unlock more Features</p>
          </div>
      </div>
  </div>
  )
}
