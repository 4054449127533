import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars,FaBalanceScale, FaSignOutAlt, FaHome } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import {  MdClose } from 'react-icons/md';
import { RiFileTextLine } from 'react-icons/ri';
import { AiOutlineSafety, AiOutlineBarChart,AiOutlineMail,AiOutlineSetting,AiOutlineQuestionCircle,AiOutlinePhone } from 'react-icons/ai';
import { HiOutlineDocumentText } from 'react-icons/hi';

export default function Dashboard() {
  const [isDashboardCollapsed, setIsDashboardCollapsed] = useState(true);
  const { logout } = useContext(AuthContext);

  const toggleDashboard = () => {
    setIsDashboardCollapsed(!isDashboardCollapsed);
  };

  return (
    <div
      className={`col-auto ${isDashboardCollapsed ? 'collapsed' : ''}`}
      style={{
        transition: 'width 0.5s',
        width: isDashboardCollapsed ? '3.5rem' : '12rem',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'lightgreen',
        height: '88vh',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}
    >
      {/* Toggle Button */}
      <div
        className="p-3  cursor-pointer"
        onClick={toggleDashboard}
        style={{ textAlign: 'center', color: '#000000' }}
      >
        {isDashboardCollapsed ? <FaBars /> : <MdClose size={30} />}
      </div>

      {/* Dashboard Links */}
      <ul
        style={{
          flex: '1',
          listStyle: 'none',
          paddingLeft: '0',
          transition: '0.5s',
        }}
      >
        {/* Main Navigation */}
        <li className="ps-3" style={{ marginTop: '1rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/home">
            <FaHome /> {!isDashboardCollapsed && 'Home'}
          </Link>
        </li>

        {/* Templates and Emails */}
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/templates">
            <RiFileTextLine /> {!isDashboardCollapsed && 'Templates'}
          </Link>
        </li>
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/emails">
            <AiOutlineMail /> {!isDashboardCollapsed && 'Email Subscriptions'}
          </Link>
        </li>

        {/* Analytics and Settings */}
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/logs">
            <AiOutlineBarChart /> {!isDashboardCollapsed && 'Tracking & Analytics'}
          </Link>
        </li>
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/settings">
            <AiOutlineSetting /> {!isDashboardCollapsed && 'Settings'}
          </Link>
        </li>

        {/* Help and Documentation */}
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/faqs">
            <AiOutlineQuestionCircle /> {!isDashboardCollapsed && 'Faqs'}
          </Link>
        </li>
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/support">
            <AiOutlinePhone /> {!isDashboardCollapsed && 'Support & Resources'}
          </Link>
        </li>
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/docs">
            <HiOutlineDocumentText /> {!isDashboardCollapsed && 'Documentation'}
          </Link>
        </li>

        {/* Privacy and Terms */}
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/pages/privacy-policy">
            <AiOutlineSafety /> {!isDashboardCollapsed && 'Privacy Policy'}
          </Link>
        </li>
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link className="text-decoration-none text-dark " to="/pages/terms-and-conditions">
            <FaBalanceScale /> {!isDashboardCollapsed && 'T&C'}
          </Link>
        </li>

        {/* Logout */}
        <li className="ps-3" style={{ marginTop: '2rem', fontSize: '1rem' }}>
          <Link onClick={logout} className="text-decoration-none text-danger ">
            <FaSignOutAlt /> {!isDashboardCollapsed && 'Logout'}
          </Link>
        </li>
      </ul>
    </div>
  );
}
