import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="container my-5" style={{ padding: '30px', borderRadius: '10px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', backgroundColor: '#ffffff' }}>
            <h2 style={{fontFamily:'Fantasy'}} className="text-center mb-4">Terms and Conditions for Netics Mailer</h2>
            <p className="text-muted text-center">Last Updated: 22nd September 2024</p>
            <div className="mt-4">
                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Subscription Service</h5>
                <p style={{ fontSize: '0.9rem' }}>Netics Mailer operates on a subscription basis. Users can subscribe to a monthly plan that allows access to our email services.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Subscription Duration</h5>
                <p style={{ fontSize: '0.9rem' }}>The subscription is valid for one month from the date of purchase. Your subscription will automatically renew each month unless cancelled prior to the renewal date.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Email Limit</h5>
                <p style={{ fontSize: '0.9rem' }}>Each subscription plan has a specified limit on the number of emails you can send within the month. If you exceed the email limit, your access to the email service will be suspended until the next billing cycle or until you upgrade your plan.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Service Suspension</h5>
                <p style={{ fontSize: '0.9rem' }}>
                    Please note that your access to Netics Mailer services will be suspended if you do not resubscribe after one month. Additionally, if you reach the email limit specified for your subscription package before the one-month period is over, your services will also be suspended. To continue using the email services, please ensure that you either resubscribe or upgrade your plan as needed.
                </p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Cancellation Policy</h5>
                <p style={{ fontSize: '0.9rem' }}>You may cancel your subscription at any time through your account settings. Cancellations will take effect at the end of the current billing cycle, and you will not be charged for the subsequent month.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Payment Terms</h5>
                <p style={{ fontSize: '0.9rem' }}>Payments are processed monthly. You agree to provide accurate payment information and authorize Netics Mailer to charge your selected payment method. All fees are non-refundable.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>User Responsibilities</h5>
                <p style={{ fontSize: '0.9rem' }}>You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree not to use the service for any unlawful purposes or to transmit any harmful or offensive content.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Modifications to Terms</h5>
                <p style={{ fontSize: '0.9rem' }}>Netics Mailer reserves the right to modify these terms at any time. Changes will be effective immediately upon posting on our website. Your continued use of the service constitutes acceptance of the modified terms.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Limitation of Liability</h5>
                <p style={{ fontSize: '0.9rem' }}>Netics Mailer will not be liable for any indirect, incidental, or consequential damages arising from your use of the service.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Governing Law</h5>
                <p style={{ fontSize: '0.9rem' }}>These terms shall be governed by the laws of Kenya. Any disputes will be resolved in the courts of Kenya.</p>

                <h5 className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Contact Information</h5>
                <p style={{ fontSize: '0.9rem' }}>For any questions regarding these terms, please contact us at <a href="mailto:info@safarinetics.com">info@safarinetics.com</a>.</p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
