import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Paper, Grid } from '@mui/material';
import LoadingComponent from '../../components/LoadingComponent';
import { TemplateTwoContext } from './TemplateTwoContext';
import UpdateForm from './UpdateForm';
import Preview from './Preview';

export default function UpdateInstance() {
   const { loading, instance } = useContext(TemplateTwoContext);
   const [formData, setFormData] = useState(instance);

   useEffect(() => {
      // Prefill the form with the initial data
      setFormData(instance);
   }, [instance]);

   

   if (loading) {
      return (
         <LoadingComponent loadingText={'Updating ....'} />
      );
   }

   return (
      <Container maxWidth="lg" sx={{ mt: 0 }}>
      <Grid container spacing={2} sx={{ height: '100%', maxHeight:'fit-content' }}>
        {/* Form on the left */}
        <Grid item xs={12} md={6}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              backgroundColor: '#C0C0C0', 
              height: 'auto', 
              maxHeight: '100%'
            }}
          >
            <Typography variant="h4" align="center" gutterBottom sx={{ mb: 2 }}>
              Update
            </Typography>
            <UpdateForm formData={formData} setFormData={setFormData}/>
          </Paper>
        </Grid>

        {/* Preview on the right */}
        <Grid item xs={12} md={6} style={{maxWidth:'50vw',width:'100%', margin:'auto'}}>
          <Preview formData={formData} />
        </Grid>
      </Grid>
    </Container>
   );
}
