import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme, Box, Typography, Paper, Grid } from '@mui/material';
import { AiOutlineUser, AiOutlineBarChart, AiOutlineFileText, AiOutlineSetting, AiOutlineUnlock, AiOutlinePhone } from 'react-icons/ai';
import { LogContext } from '../context/LogContext';
import { EmailContext } from '../context/EmailContext';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4caf50', // Dark green
        },
        secondary: {
            main: '#81c784', // Light green
        },
        background: {
            default: '#f5f5f5', // Light background for the main page
        },
        text: {
            primary: '#333',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h5: {
            fontWeight: 'bold',
        },
        h6: {
            fontWeight: '600',
        },
        body1: {
            fontSize: '0.875rem',
        },
    },
});

const Home = () => {
  const {totalRecipients,clickRate,openRate} = useContext(LogContext)
  const {unsubscribedCount,subscribedCount} = useContext(EmailContext)
  
    const navigate = useNavigate();
    const serverLink='https://besh.newsletter.safarinetics.com'

    return (
        <ThemeProvider theme={theme}>
            <motion.div 
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }} 
                transition={{ duration: 0.8 }} 
                style={{ padding: '20px' }}>
                
                <motion.p 
                    initial={{ x: -50, opacity: 0 }} 
                    animate={{ x: 0, opacity: 1 }} 
                    transition={{ duration: 0.8 }} 
                    style={{ textAlign: 'center', fontSize: '1.0rem' }}>
                    Hello, <strong>{process.env.REACT_APP_NAME}</strong>
                </motion.p>

                <Box sx={{ width: '100%', margin: 'auto', padding: '20px' }}>
                    <motion.div 
                        initial={{ y: -50, opacity: 0 }} 
                        animate={{ y: 0, opacity: 1 }} 
                        transition={{ duration: 0.6 }} 
                        style={{ marginBottom: '20px' }}>
                        <Paper elevation={3} sx={{display:'flex',flexDirection:'column', padding: '30px',alignItems: 'center', backgroundColor: 'rgb(144, 238, 144, 0.336)' }}>
                            <Typography variant="h5" sx={{ color: 'primary.main' }}>
                                Netics Mailer Dashboard Overview
                            </Typography>
                            <Typography variant="body1">
                                Manage your <span className='fw-semibold fst-italic'>email campaigns</span>, 
                                <span className='fw-semibold fst-italic'> track performance</span>, and 
                                <span className='fw-semibold fst-italic'> edit templates</span> all in one place.
                            </Typography>
                        </Paper>
                    </motion.div>

                    <Grid container spacing={2}>
                        {[
                            { title: 'Templates', value: '2' },
                            { title: 'Active Subscribers', value: subscribedCount || 0 },
                            { title: 'Unsubscribed Emails', value: unsubscribedCount || 0},
                            { title: 'Emails Sent ', value: totalRecipients || 0},
                            { title: 'Open Rate', value: `${openRate || 0} %`},
                            { title: 'Click Rate', value: `${clickRate || 0} %`}
                        ].map((stat, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <motion.div 
                                    initial={{ opacity: 0 }} 
                                    whileInView={{ opacity: 1 }} 
                                    transition={{ duration: 0.6, delay: index * 0.2 }} 
                                    whileHover={{ scale: 1.05 }} >
                                    <Paper elevation={2} sx={{ padding: '20px', background: 'rgba(144, 238, 144, 0.41)', textAlign: 'center' }}>
                                        <Typography variant="h6" sx={{ color: 'primary.main' }}>{stat.title}</Typography>
                                        <Typography variant="body1">{stat.value}</Typography>
                                    </Paper>
                                </motion.div>
                            </Grid>
                        ))}
                    </Grid>

                    <motion.h2 
                        initial={{ opacity: 0, y: 50 }} 
                        whileInView={{ opacity: 1, y: 0 }} 
                        transition={{ duration: 0.6 }} 
                        style={{ color: '#4caf50', textAlign: 'center', fontWeight: 'bold', marginTop: '30px' }}>
                        Manage Your Campaigns
                    </motion.h2>
                    
                    <Grid container spacing={2}>
                        {[
                            { link: '/templates', title: 'Email Templates', description: 'You currently have 3 templates available. Edit and customize your email templates with our intuitive editor.', icon: <AiOutlineFileText /> },
                            { link: '/emails', title: 'Subscriber Management', description: 'View and manage your subscribers. Add new subscribers manually or upload a spreadsheet.', icon: <AiOutlineUser /> },
                            { link: '/logs', title: 'Tracking & Analytics', description: 'Access detailed logs to track email openings and link clicks for each campaign.', icon: <AiOutlineBarChart /> },
                            { link: '/subscription', title: 'Subscription Plan', description: 'You are currently subscribed to the Premium Plan. Next billing date: 2024-10-01. Plan Details: Send up to 10,000 emails per month with tracking.', icon: <AiOutlineSetting /> },
                            { link: '/settings/auth/password/change', title: 'Change Password', description: 'Update your account password for enhanced security.', icon: <AiOutlineUnlock /> },
                            { link: '/support', title: 'Support & Resources', description: 'Access our help center for tutorials and customer support.', icon: <AiOutlinePhone /> },
                        ].map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <motion.div 
                                    initial={{ opacity: 0, y: 50 }} 
                                    whileInView={{ opacity: 1, y: 0 }} 
                                    transition={{ duration: 0.6, delay: index * 0.2 }} 
                                    whileHover={{ scale: 1.05 }} 
                                    onClick={() => navigate(feature?.link)}
                                    style={{ cursor: 'pointer' }}>
                                    <Paper elevation={2} sx={{ padding: '20px', background: 'rgba(144, 238, 144, 0.31)', textAlign: 'center' }}>
                                        <Typography variant="h6" sx={{ color: 'primary.main' }}>{feature.icon} {feature.title}</Typography>
                                        <Typography variant="body2">{feature.description}</Typography>
                                    </Paper>
                                </motion.div>
                            </Grid>
                        ))}
                    </Grid>

                    <motion.div 
                        initial={{ opacity: 0, y: 50 }} 
                        whileInView={{ opacity: 1, y: 0 }} 
                        transition={{ duration: 0.6 }} 
                        style={{ margin: '30px 0' }}>
                        <Paper elevation={3} sx={{ padding: '20px', background: 'rgba(144, 238, 144, 0.31)' }}>
                            <Typography variant="h5" sx={{ color: 'primary.main' }}>Developer Integration</Typography>
                            <Typography>Integrate email subscriptions from your website footer:</Typography>
                            <pre style={{ background: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
                                {`POST ${serverLink}/emails/add/website
                                {
                                    "email": "subscriber@example.com"
                                }`}
                            </pre>
                            <Typography>Welcome Message:</Typography>
                            <Typography>Thank you for subscribing!</Typography>
                        </Paper>
                    </motion.div>
                </Box>
            </motion.div>
        </ThemeProvider>
    );
};

export default Home;
