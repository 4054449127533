import React, { useContext, useState, useEffect } from 'react';
import { MdCancel, MdCheck } from 'react-icons/md';
import { LogContext } from '../context/LogContext';

export default function LogDetails({ logs }) {
  const { formatToCustomDate, fetchGeolocation } = useContext(LogContext);
  const [geoLocations, setGeoLocations] = useState({});

  useEffect(() => {
    // Fetch geolocation for each log and update the state
    const fetchAllGeolocations = async () => {
      const locations = {};
      for (const log of logs) {
        if (log?.ip_address) {
          const location = await fetchGeolocation(log.ip_address);
          locations[log.id] = location || 'Unknown location';
        }
      }
      setGeoLocations(locations);
    };

    if (logs && logs.length > 0) {
      fetchAllGeolocations();
    }
  }, [logs, fetchGeolocation]);


  return (
    <div style={{ overflow: 'auto', width: '100%' }}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Recipient</th>
            <th>Opened</th>
            <th>Link (last)</th>
            <th>Device</th>
            <th>Geolocation</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {logs && logs.length > 0 && logs.map((log, index) => (
            <tr key={log.id}>
              <td>{index + 1}</td>
              <td>{log.recipient}</td>
              <td>{log.is_opened ? <MdCheck color="green" size={20} /> : <MdCancel color="red" size={20} />}</td>
              <td>{log?.last_clicked_link || ''}</td>
              <td>{log?.device_type || ''}</td>
              <td>{geoLocations[log.id] || ''}</td>
              <td>{formatToCustomDate(log?.last_opened)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
