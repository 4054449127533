import React from 'react';
import { motion } from 'framer-motion';
import BillingHistory from './BillingHistory';
import CurrentPlan from './CurrentPlan';
import PlanUsage from './PlanUsage';

const Subscription = () => {
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f0f8f5', color: '#333', padding: '20px', minHeight: '100vh' }}>
            <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                style={{ maxWidth: '900px', margin: 'auto', padding: '20px', backgroundColor: 'white', borderRadius: '10px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
                <h2 style={{ color: 'green', textAlign: 'center', marginBottom: '20px' }}>Subscription and Billing Information</h2>

                {/* Current Plan */}
                <CurrentPlan/>

                {/* Billing History */}
                <BillingHistory />

                {/* Plan Usage */}
                <PlanUsage/>
            </motion.div>
        </div>
    );
};

export default Subscription 
