import React from 'react';
import * as envVars from '../utils/EnvVariables';

export default function Footer() {
  return (
    <footer className="footer" style={{ backgroundColor: '#C0D8B5', paddingTop: '10px', paddingBottom: '10px', height: 'fit-content' }}>
      <div className="container-fluid">
        <div className="row container-fluid mx-auto">
          <div className="col-md-8 mx-auto">
            <p className="text-md-center" style={{ fontSize: '0.8rem' }}>© {new Date().getFullYear()} {envVars.COMPANY_NAME} . All rights reserved.</p>
          </div>
          <div className="col-md-4">
            <p className="text-md-right" style={{ fontSize: '0.8rem' }}>Powered by:  <a href='https://safarinetics.com' rel="noopener">Safari Netics</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
}