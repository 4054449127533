export const NAME = process.env.REACT_APP_NAME;
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME;
export const EMAIL = process.env.REACT_APP_EMAIL;
export const EMAIL_PASSWORD = process.env.REACT_APP_EMAIL_PASSWORD;
export const TEST_EMAIL = process.env.REACT_APP_TEST_EMAIL;

export const LOGO = process.env.REACT_APP_LOGO;
export const WEBSITE_LINK = process.env.REACT_APP_WEBSITE_LINK;
export const FACEBOOK_LINK = process.env.REACT_APP_FACEBOOK_LINK;
export const INSTAGRAM_LINK = process.env.REACT_APP_INSTAGRAM_LINK;
export const YOUTUBE_LINK = process.env.REACT_APP_YOUTUBE_LINK;
export const LINKEDIN_LINK = process.env.REACT_APP_LINKEDIN_LINK;
export const X_LINK = process.env.REACT_APP_X_LINK;
export const SERVER_URL=process.env.REACT_APP_SERVER_URL