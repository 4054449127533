import React from 'react'
import {AiOutlineArrowUp } from 'react-icons/ai';
import { motion } from 'framer-motion';

export default function CurrentPlan() {
  return (
    <div style={{ backgroundColor: 'lightgreen', borderRadius: '10px', padding: '20px', marginBottom: '30px' }}>
                    <h3 style={{ color: 'darkgreen' }}>Current Subscription Plan</h3>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <p><strong>Plan:</strong> Standard</p>
                            <p><strong>Next Billing Date:</strong> 2024-10-01</p>
                            <p><strong>Emails Allowed:</strong> 10,000 per month</p>
                            <p><strong>Tracking:</strong> OPen Rates and Click Rates</p>
                        </div>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            style={{ padding: '10px 20px', border: 'none', borderRadius: '5px', backgroundColor: 'darkgreen', color: 'white', cursor: 'pointer' }}
                        >
                            Upgrade Plan <AiOutlineArrowUp style={{ marginLeft: '5px' }} />
                        </motion.button>
                    </div>
                </div>
  )
}
