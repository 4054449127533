import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { Typography, Button, Paper } from '@mui/material';
import TawkTo from '../components/TawkTo';

export default function FurtherAssistance({theme}) {
   const [showTawkTo, setShowTawkTo] = useState(false);

   useEffect(() => {
     const checkTawkToStatus = () => {
       if (showTawkTo) {
         setShowTawkTo(false); // Set to false if it is true
         // console.log('showTawkTo is now set to false');
       }
     };
 
     // Set up a timer that runs every 5 minutes (300,000 ms)
     const timer = setInterval(checkTawkToStatus, 300000);
 
     // Cleanup interval on component unmount
     return () => clearInterval(timer);
   }, [showTawkTo]); // Depend on showTawkTo, so it resets if the value changes
 
   function handleClick(){
      setShowTawkTo(true);
   }
  return (
   <motion.div
      initial={{ opacity: 0, y: 50 }} 
      whileInView={{ opacity: 1, y: 0 }} 
      transition={{ duration: 0.6 }}
      style={{
         textAlign: 'center',
      }}
   >
      <Paper elevation={3} style={{ padding: '30px', borderRadius: '10px', backgroundColor: '#c4e7c4' }}>
         <Typography variant="h5" style={{ marginBottom: '20px', color: theme.palette.primary.main }}>
               Need Further Assistance?
         </Typography>
         <Typography style={{ marginBottom: '20px' }}>
               Our support team is always ready to assist you. Feel free to reach out for any inquiries!
         </Typography>
         <Button onClick={handleClick} variant="contained" color="primary">Contact Support</Button>
      </Paper>
      {showTawkTo && (
         <TawkTo/>
      )}
   </motion.div>
  )
}
