// src/App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home'
import Layout from './layout/Layout';
import Landing from './components/Landing';
import Unauthorised from './components/Unauthorised';
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import ResetChangePassword from './auth/ResetChangePassword';
import AuthProvider from './context/AuthContext';
import EmailProvider from './context/EmailContext';
import LogProvider from './context/LogContext';
import ChangePassword from './settings/ChangePassword';
import Emails from './emails/Emails';
import Setting from './settings/Setting';
import Templates from './templates/Templates';
import Logs from './logs/Logs';
import PrivacyPolicy from './components/PrivacyPolicy';
import AllTemplates from './templates/AllTemplates';
import Template1Routes from './templates/Temp1/Template1Routes';
import Template2Routes from './templates/temp2/Template2Routes';
import TermsAndConditions from './components/TermsAndConditions';
import Subscription from './subscription/Subscription';
import Profile from './profile/Profile';
import SupportAndResources from './support/SupportAndResources';
import { ThemeProvider } from './context/ThemeContext';
import Documentation from './documentation/Documentation';
import Dashboard from './documentation/Dashboard';
import GetStartedGuide from './documentation/GetStartedGuide';
import ApiIntegration from './documentation/ApiIntegration';
import BestPracticeForCampaign from './documentation/BestPracticeForCampaign';
import AdvancedTrackingFeatures from './documentation/AdvancedTrackingFeatures';
import Faqs from './support/Faqs';


export default function App() {
  return (
    <BrowserRouter>
    
      <AuthProvider>
        <EmailProvider>
            <LogProvider>
              <ThemeProvider>
              <Routes>
                <Route path='/' element={<Layout />}>
                  <Route index element={<Landing />} />
                  <Route path='*' element={<Unauthorised />} />
                  <Route path='/home' element={<Home/>} />

                  <Route path='/pages/privacy-policy' element={<PrivacyPolicy />} />
                  <Route path='/pages/terms-and-conditions' element={<TermsAndConditions />} />

                  <Route path='/auth/login' element={<Login />} />
                  <Route path='/auth/password/reset' element={<ResetPassword />} />
                  <Route path='/auth/password/:email/change' element={<ResetChangePassword />} />

                  <Route path='/emails' element={<Emails />} />

                  <Route path='/subscription' element={<Subscription />} />

                  <Route path='/profile' element={<Profile/>} />

                  <Route path='/support' element={<SupportAndResources/>} />
                  
                  <Route path='/templates' element={<AllTemplates />}>
                    <Route index element={< Templates/>} />
                    <Route path='1/*' element={<Template1Routes />} />
                    <Route path='2/*' element={<Template2Routes />} />
                    {/* Add Routes for another template here */}

                  </Route>

                  <Route path='/docs' element={<Documentation />}>
                    <Route index element={< Dashboard/>} />
                    <Route path='getting-started' element={<GetStartedGuide />} />
                    <Route path='api-integration' element={<ApiIntegration />} />
                    <Route path='best-practices' element={<BestPracticeForCampaign />} />
                    <Route path='tracking-features' element={<AdvancedTrackingFeatures />} />
                    {/* Add Routes for another template here */}

                  </Route>


                  <Route path='/settings' element={<Setting />} />
                  <Route path='/settings/auth/password/change' element={<ChangePassword />} />


                  <Route path='/logs' element={<Logs />} />
                  <Route path='/faqs' element={<Faqs />} />
                </Route>
              </Routes>
              </ThemeProvider>
            </LogProvider>
        </EmailProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
