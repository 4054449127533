import React, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosInstance from '../utils/RequestsQueue'; // Adjust the path as needed

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUserState] = useState(() => sessionStorage.getItem('currentUser') ? JSON.parse(sessionStorage.getItem('currentUser')) : null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authToken, setAuthTokenState] = useState(() => localStorage.getItem('beshNewsletterAuthToken') ? localStorage.getItem('beshNewsletterAuthToken') : null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  // Update auth token in session storage and state
  const setAuthToken = (token) => {
    localStorage.setItem('beshNewsletterAuthToken', token);
    setAuthTokenState(token);
  };

  // Update currentUser in session storage and state
  const setCurrentUser = (user) => {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
    setCurrentUserState(user);
  };

  // ============================ POST/CREATE/ADD ============================

  // login
  function login(userData) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      url: `${process.env.REACT_APP_SERVER_URL}/users/login`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: userData
    })
    .then((response) => {
      if (response.data.access_token) {
        setAuthToken(response.data.access_token);
        setLoading(false);
        navigate('/home');
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Log in success!',
          showConfirmButton: false,
          timer: 1500,
          color: 'wheat',
          toast: true,
          background: '#228B22'
        });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: response.data.error || response.data.msg,
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          color: 'wheat',
          background: 'red'
        });
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  // reset Password
  function resetPassword(userData) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      url: `${process.env.REACT_APP_SERVER_URL}/users/auth/password/reset`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: userData
    })
    .then((response) => {
      if (response.data.success) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.success,
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          color: 'wheat',
          background: '#228B22'
        });
        navigate('/auth/login');
      } else if (response.data.error) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.data.error,
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          color: 'wheat',
          background: 'red'
        });
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  // ============================ GET/FETCH ============================

  // Get Authenticated(logged) userData
  useEffect(() => {
    if (authToken) {
      axiosInstance.requestWithQueue({
        url: `${process.env.REACT_APP_SERVER_URL}/users/logged`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      .then((response) => {
        if (!response.data.error || !response.data.msg) {
          setCurrentUserState(response.data);
          setLoggedIn(true);
        }
        else if(location.pathname !== '/pages/privacy-policy'){
          // console.log(response)
        navigate('/auth/login');
        setAuthToken(null);
        localStorage.removeItem('beshNewsletterAuthToken');
        }
      })
      .catch((error) => {
        console.error('Error:1', error);
        if(location.pathname !== '/pages/privacy-policy'){
          // console.log(response)
        navigate('/auth/login');
        // setAuthToken(null);
        }
      });
    }else if(location.pathname !== '/pages/privacy-policy'){

      navigate('/auth/login');
    }
  }, [authToken]);

  // ============================ PUT/PATCH/UPDATE ============================

  // change Password
  function changePassword(password) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      url: `${process.env.REACT_APP_SERVER_URL}/users/auth/password/change`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      data: { password }
    })
    .then((response) => {
      if (response.data.success) {
        setLoading(false);
        Swal.fire({
          icon: 'success',
          text: response.data.success,
          position: 'top-end',
          timer: 2000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat'
        });
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 2000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat'
        });
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  // Reset change Password
  function resetChangePassword(formData) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      url: `${process.env.REACT_APP_SERVER_URL}/users/auth/reset/password/change`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      data: formData
    })
    .then((response) => {
      if (response.data.success) {
        setLoading(false);
        navigate('/auth/login');
        Swal.fire({
          icon: 'success',
          text: response.data.success,
          position: 'top-end',
          timer: 2000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat'
        });
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 2000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat'
        });
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  // ============================ LOGOUT/DELETE ============================

  // logout
  function logout() {
    setLoading(true);
    axiosInstance.requestWithQueue({
      url: `${process.env.REACT_APP_SERVER_URL}/users/logout`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
    .then((response) => {
      if (response.data.success) {
        navigate('/');
        setCurrentUser(null);
        setAuthToken(null);
        setLoggedIn(false);
        sessionStorage.removeItem('currentUser');
        localStorage.removeItem('beshNewsletterAuthToken');
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.success,
          showConfirmButton: false,
          timer: 1500,
          color: 'wheat',
          toast: true,
          background: '#228B22'
        });
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  const contextData = {
    authToken,
    login,
    currentUser,
    loggedIn,
    logout,
    changePassword,
    resetPassword,
    loading,
    setLoading,
    resetChangePassword
  };

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
}
