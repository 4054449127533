import React from 'react';
import { motion } from 'framer-motion';
import { createTheme, Typography, Paper, Breadcrumbs, Link } from '@mui/material';
import { Outlet, useLocation, Link as RouterLink } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50', // Main green color
    },
    background: {
      default: '#eafaf1', // Light green background
    },
  },
});

export default function Documentation() {
  const location = useLocation();

  // Generate the breadcrumb paths
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      style={{
        marginBottom: '40px',
      }}
    >
      <Paper elevation={3} style={{ padding: '30px', borderRadius: '10px', backgroundColor: '#c4e7c4' }}>
        <Typography variant="h3" style={{ textAlign: 'center', marginBottom: '20px', color: theme.palette.primary.main }}>
          Documentation
        </Typography>

        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '20px' }}>
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to="/home"
          >
            Home
          </Link>
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return index === pathnames.length - 1 ? (
              <Typography color="textPrimary" key={to}>
                {value.charAt(0).toUpperCase() + value.slice(1).replace('-', ' ')}
              </Typography>
            ) : (
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to={to}
                key={to}
              >
                {value.charAt(0).toUpperCase() + value.slice(1).replace('-', ' ')}
              </Link>
            );
          })}
        </Breadcrumbs>

        <Outlet />
      </Paper>
    </motion.div>
  );
}
