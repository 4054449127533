import React, { createContext, useContext, useState } from 'react';

// Create a context for the theme
const ThemeContext = createContext();

// Define the default theme
const defaultTheme = {
    colors: {
        primary: '#4CAF50', // Main green color
        secondary: '#388E3C', // Darker green
        accent: '#C8E6C9', // Light green for backgrounds
        text: '#333', // Dark text for readability
        lightText: '#FFFFFF', // Light text for buttons/overlays
        border: '#A5D6A7', // Subtle border color
    },
    typography: {
        fontFamily: '"Arial", sans-serif',
        fontSize: '16px',
        headingFont: '"Georgia", serif',
        headingSize: '24px',
        textSize: '14px',
    },
    spacing: {
        small: '8px',
        medium: '16px',
        large: '24px',
        xLarge: '32px',
    },
    button: {
        borderRadius: '5px',
        padding: '10px 20px',
    },
    shadows: {
        light: '0 2px 4px rgba(0, 0, 0, 0.1)',
        medium: '0 4px 8px rgba(0, 0, 0, 0.2)',
        heavy: '0 8px 16px rgba(0, 0, 0, 0.3)',
    },
};

// Theme Provider component
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(defaultTheme);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Custom hook to use the theme
export const useTheme = () => {
    return useContext(ThemeContext);
};

// Example of usage in a component
// import { useTheme } from './ThemeContext';

// const SomeComponent = () => {
//     const { theme } = useTheme();
//     return (
//         <div style={{ color: theme.colors.text, backgroundColor: theme.colors.accent }}>
//             <h1 style={{ fontFamily: theme.typography.headingFont }}>Welcome</h1>
//             <button style={{
//                 backgroundColor: theme.colors.primary,
//                 color: theme.colors.lightText,
//                 borderRadius: theme.button.borderRadius,
//                 padding: theme.button.padding,
//                 boxShadow: theme.shadows.light,
//             }}>Click Me</button>
//         </div>
//     );
// };

export default ThemeContext;
