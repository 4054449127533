import React from 'react'
import { motion } from 'framer-motion';
import { createTheme,  Typography,  Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import FurtherAssistance from './FurtherAssistance';

const theme = createTheme({
   palette: {
       primary: {
           main: '#4caf50', // Main green color
       },
       background: {
           default: '#eafaf1', // Light green background
       },
   },
});
export default function Faqs() {
    const location = useLocation()
  return (
   <motion.div
        initial={{ opacity: 0, y: 50 }} 
        whileInView={{ opacity: 1, y: 0 }} 
        transition={{ duration: 0.6 }}
        style={{
            marginBottom: '40px',
        }}
    >
        <Paper elevation={3} style={{ padding: '30px', borderRadius: '10px', backgroundColor: '#c4e7c4' }}>
            <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px', color: theme.palette.primary.main }}>
                Frequently Asked Questions
            </Typography>

            {[
                { question: 'How do I reset my password?', answer: 'You can reset your password by navigating to your Account Settings > Change Password.' },
                { question: 'How do I upgrade my subscription?', answer: 'To upgrade your subscription, go to Account Settings > Subscription and choose a new plan.' },
                { question: 'What is the email sending limit on my plan?', answer: 'You can check your email sending limit in your dashboard overview or by visiting the Subscription page in Account Settings.' },
                { question: 'How do I track email performance?', answer: 'You can track the performance of your email campaigns through the Tracking & Analytics page, where you can see open rates, click rates, and more.' },
                { question: 'Can I integrate the service with my website?', answer: 'Yes, we offer API integration that allows you to connect email subscription forms to your website. Check out our Developer Documentation for more details.' }
            ].map((faq, index) => (
                <motion.div 
                    key={index}
                    initial={{ opacity: 0 }} 
                    whileInView={{ opacity: 1 }} 
                    transition={{ duration: 0.6, delay: index * 0.2 }}
                    style={{ marginBottom: '20px', padding: '15px', background: '#c4e7c4', borderRadius: '8px' }}
                >
                    <Typography><strong>Q:</strong> {faq.question}</Typography>
                    <Typography style={{ marginLeft: '15px' }}><strong>A:</strong> {faq.answer}</Typography>
                </motion.div>
            ))}
        </Paper>
        {location.pathname === '/faqs' && (
            <FurtherAssistance theme={theme}/>
        )}
        
    </motion.div>

  )
}
