import React,{useState, useContext, useEffect} from 'react'
import { AiOutlineLock } from 'react-icons/ai';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2'
import LoadingComponent from '../components/LoadingComponent';
import { AuthContext } from '../context/AuthContext';

export default function ChangePasswordForm() {
     const { changePassword, loading, setLoading } = useContext(AuthContext);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [strength, setStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(true); // Track password match status


  const calculateStrength = (password) => {
    let score = 0;
    if (password.length >= 8) score += 20;
    if (/[A-Z]/.test(password)) score += 20;
    if (/[a-z]/.test(password)) score += 20;
    if (/\d/.test(password)) score += 20;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score += 20;
    setStrength(score);
  };

  const validateForm = () => {
    if (strength < 80) {
      Swal.fire({
        title: 'Weak Password',
        text: 'Password must be strong (at least 80% strength).',
        icon: 'warning',
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        position: 'top',
        background: 'orange',
      });
      return false;
    }
    if (!passwordMatch) {
      Swal.fire({
        title: 'Passwords do not match',
        text: 'Please ensure the passwords match.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        position: 'top',
        background: 'orange',
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    setPasswordMatch(newPassword === confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      changePassword(newPassword);
      setNewPassword('');
      setConfirmNewPassword('');
    }
  };


  if (loading) {
    return <LoadingComponent loadingText={'Changing Password ....'} />;
  }
  return (
   <div style={{ backgroundColor: '#d0f0c0', borderRadius: '10px', padding: '20px', marginBottom: '30px' }}>
   <h3 style={{ color: 'darkgreen' }}>Change Password</h3>

   {newPassword && (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>

          <div style={{
            height: '5px',
            width: '100%',
            backgroundColor: '#e0e0e0',
            marginBottom: '10px',
          }}>
            <div
            className='rounded'
              style={{
                height: '100%',
                width: `${strength}%`,
                backgroundColor: strength < 50 ? 'red' : strength < 80 ? 'yellow' : 'green',
                transition: 'width 0.5s ease-in-out',
              }}
            />
          </div>
          <p style={{ marginTop: '5px', color: strength < 50 ? 'red' : strength < 80 ? 'yellow' : 'green'}}>
          <small>({strength}%)</small>
          </p>
        </div>
      )}
   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
       <AiOutlineLock size={30} color="green" />
       <input
           type="password"
           value={newPassword}
           onChange={(e) => {
            setNewPassword(e.target.value);
            calculateStrength(e.target.value);
          }}
           style={{
               marginLeft: '10px',
               padding: '10px',
               width: '100%',
               borderRadius: '5px',
               border: '1px solid #ccc',
               backgroundColor: '#f0f8f5',
           }}
           placeholder="New Password"
       />
   </div>

   {!passwordMatch && confirmNewPassword && (
            <div style={{
              color: 'red',
              marginTop: '5px',
              fontSize: '14px',
            }}> * Passwords do not match</div>
          )}
   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
       <AiOutlineLock size={30} color="green" />
       <input
           type="password"
           value={confirmNewPassword}
           onChange={(e) => setConfirmNewPassword(e.target.value)}
           style={{
               marginLeft: '10px',
               padding: '10px',
               width: '100%',
               borderRadius: '5px',
               border: '1px solid #ccc',
               backgroundColor: '#f0f8f5',
           }}
           placeholder="Confirm Password"
       />
   </div>
   <motion.button
       whileHover={{ scale: 1.05 }}
       onClick={handleSubmit}
       className='btn btn-success'
       style={{
           marginTop: '15px',
           padding: '10px 20px',
           border: 'none',
           borderRadius: '5px',
           cursor: 'pointer',
       }}
       disabled={strength < 80 || !passwordMatch || loading}
   >
       Update Password
   </motion.button>
</div>
  )
}
