import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Grid,  Paper } from '@mui/material';
import { TemplateOneContext } from './TemplateOneContext';
import LoadingComponent from '../../components/LoadingComponent';
import Preview from './Preview';
import UpdateForm from './UpdateForm';

export default function UpdateInstance() {
  const { loading,instance } = useContext(TemplateOneContext);
  
  const [formData, setFormData] = useState(instance);
  useEffect(() => {
    // Prefill the form with the initial data
    setFormData(instance);
  }, [instance]);

  // const handleImageChange = (e) => {
  //   setFormData({ ...formData, image: e.target.files[0] });
  // };


  if (loading) {
    return <LoadingComponent loadingText={'Updating ....'} />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 0 }}>
      <Grid container spacing={2} sx={{ height: '100%', maxHeight:'170vh' }}>
        {/* Form on the left */}
        <Grid item xs={12} md={6}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              backgroundColor: '#C0C0C0', 
              height: '100%', 
              // maxHeight: '140vh', 
              overflow: 'auto' 
            }}
          >
            <Typography variant="h4" align="center" gutterBottom sx={{ mb: 2 }}>
              Update
            </Typography>
            <UpdateForm formData={formData} setFormData={setFormData}/>
          </Paper>
        </Grid>

        {/* Preview on the right */}
        <Grid item xs={12} md={6} style={{maxWidth:'45vw',width:'100%'}}>
          <Preview formData={formData} />
        </Grid>
      </Grid>
    </Container>
  );
}
