import React from 'react'
import { motion } from 'framer-motion';

export default function BillingHistory() {
  return (
    <div style={{ backgroundColor: 'lightgreen', borderRadius: '10px', padding: '20px', marginBottom: '30px' }}>
                    <h3 style={{ color: 'darkgreen' }}>Billing History</h3>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#e0ffe0' }}>
                                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Date</th>
                                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Invoice</th>
                                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Amount</th>
                                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[
                                { date: '2024-09-01', invoice: '#INV12345', amount: '99.99', item: 'Installation', status: 'Paid' },
                                { date: '2024-08-01', invoice: '#INV12344', amount: '99.99', item: 'Basic Package', status: 'Paid' },
                                { date: '2024-07-01', invoice: '#INV12343', amount: '99.99', item: 'Standard Package', status: 'Paid' },
                            ].map((item, index) => (
                                <motion.tr
                                    key={index}
                                    initial={{ opacity: 0, x: -30 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                >
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{item.date}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{item.invoice}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>ksh {item.amount}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: 'green' }}>{item.status}</td>
                                </motion.tr>
                            ))}
                        </tbody>
                    </table>
                </div>
  )
}
