import React, { useState, useEffect, useContext }  from 'react'
import { TemplateTwoContext } from './TemplateTwoContext';
import { uploadImage } from '../../components/ImageUploader';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

export default function UpdateForm({formData, setFormData}) {
   const { updateTemp2Instance, setLoading, instance, instances } = useContext(TemplateTwoContext);
   const { instanceId } = useParams();
   const [subjectExists, setSubjectExists] = useState(false); // State for subject existence
   useEffect(() => {
      // Check if the subject already exists in the instances, excluding itself
      const exists = instances && instances.length>0 && instances.some(inst => inst.subject.toLowerCase() === formData?.subject?.toLowerCase() && inst?.id !== instance?.id);
      setSubjectExists(exists);
   }, [formData.subject, instances, instance]);

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleFileChange = (e) => {
      const { name, files } = e.target;
      if (files.length > 0) {
        const url = URL.createObjectURL(files[0]);
         setFormData({ ...formData, [name]: files[0], [`${name}_url`] : url });
      }
   };

   const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent default form submission behavior

      if (checkEquality(formData,instance)){
        Swal.fire({
          position: "top-end",
          icon: "warning",
          text: `No Changes detected.`,
          showConfirmButton: false,
          timer: 5000,
          toast: true,
          color: 'black',
          background: 'orange'
       });
       return
      }
      setLoading(true);
  
      // Check if formData is populated
      if (!formData) {
         console.error("formData object is not populated");
         return; // Exit the function if formData is not available
      }
  
      // Prepare the JSON object
      const jsonData = {
         formData: formData
      };
  
      // Prepare to handle images
      const imagesToUpload = [
         { key: 'head_image', value: formData.head_image },
         { key: 'sub1_image', value: formData.sub1_image },
         { key: 'sub2_image', value: formData.sub2_image },
         { key: 'sub3_image', value: formData.sub3_image }
      ];
  
      for (const { key, value } of imagesToUpload) {
         if (value instanceof File) {
            try {
               const result = await uploadImage(value);
               
               // Add image URL and ID to jsonData after successful upload
               jsonData.formData[`${key}_id`] = result.id; // Store image ID
               jsonData.formData[key] = result.url; // Store image URL
            } catch (error) {
               console.error(`Error uploading ${key}:`, error);
               Swal.fire({
                  position: "top-end",
                  icon: "warning",
                  text: error || `An error occurred during ${key} upload.`,
                  showConfirmButton: false,
                  timer: 1500,
                  toast: true,
                  color: 'wheat',
                  background: 'red'
               });
               return; // Terminate the function if there is an error
            }
         } else if (value) {
            // Use the existing image data if it's not a new file
            jsonData.formData[key] = value; 
            jsonData.formData[`${key}_id`] = formData[`${key}_id`]; // Assuming you have the IDs in the formData object
         }
      }
  
      try {
        // console.log(jsonData)
         // Call the function to update the instance with JSON data
         await updateTemp2Instance(instanceId, jsonData?.formData);
         // Handle success (e.g., show a success notification)
      } catch (error) {
         console.error("Failed to update the template instance:", error);
         // Handle error (e.g., show an error notification to the user)
      } 
   };

   function checkEquality(obj1, obj2) {
    // Check if both are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    // Get keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if all keys and values are the same
    for (let key of keys1) {
        if (!keys2.includes(key) || obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

function handleDiscard(){

  Swal.fire({
    title: "Are you sure?",
    text: "Note: Your changes will be discarded! Would you like to continue?",
    icon: "warning",
    color:'black',
    toast:true,
    showCancelButton: true,
    confirmButtonColor: "green",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, continue",
    background:'orange' // change the colorcode 
  }).then((result) => {
    if (result.isConfirmed) {
      setFormData(instance)
    }
  });
}

function navigateBack(){
  if(!checkEquality(formData,instance)){
    Swal.fire({
      title: "Are you sure?",
      text: "Note: Your changes will be Discarded! Would you like to continue?",
      icon: "warning",
      color:'black',
      toast:true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Continue",
      background:'orange' // change the colorcode 
    }).then((result) => {
      if (result.isConfirmed) {
        setFormData(instance)
        window.history.back()
      }
    });
  }else{
    window.history.back()
  }
}
function handleDiscardColors(){
  Swal.fire({
    title: "Are you sure?",
    text: "Note: Your color styles will be reverted to the original ones! Would you like to continue?",
    icon: "warning",
    color:'black',
    toast:true,
    showCancelButton: true,
    confirmButtonColor: "green",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, continue",
    background:'rgb(255, 0, 0,0.8)' // change the colorcode 
  }).then((result) => {
    if (result.isConfirmed) {
      discardColorStyles()
    }
  });
}
function discardColorStyles() {
  const initialColors = {
    "btn_bd_color": "#808000",
    "btn_bg_color": "#32CD32",
    "btn_text_color": "#808000",
    "container_bg_color": "#98FF98",
    "footer_bg_color": "#333333",
    "footer_link_color": "#07c834",
    "footer_text_color": "#F5DEB3",
    "footer_top_bd_color": "#FFD700",
    "head_text_color": "#F5F5F5",
    "head_title_color": "#F5DEB3",
    "main_text_color": "#666666",
    "main_title_color": "#333333",
    "section1_text_color": "#666666",
    "section1_title_color": "#28a745",
    "section2_text_color": "#666666",
    "section2_title_color": "#28a745",
    "section3_text_color": "#666666",
    "section3_title_color": "#28a745",
  };

  setFormData(prevFormData => ({
    ...prevFormData, // Retain existing keys and values
    ...initialColors, // Update with initialColors
  }));
  // console.log(formData)
}


  return (
    
   <div component="form"  className=' p-3 rounded'>
<form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: 'auto' }}>

<div className="mb-3">
  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Subject</label>
  <input
    type="text" 
    className="form-control" 
    id='subject'
    name='subject'
    value={formData?.subject}
    onChange={handleChange}
    style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
    />
    {subjectExists && (
      <small className='form-label text-danger'>The subject already exists!</small>
    )}
</div>

<div className="accordion" id="accordionPanelsStayOpen">
  {/* head section */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Head Section
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
      <div className="accordion-body">
        
      <div className="mb-3">
          <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Head Title</label>
          <input
           type="text" 
           className="form-control" 
           id='head_title'
           name='head_title'
           value={formData?.head_title}
           onChange={handleChange}
           style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
            />
        </div>

        <div className="mb-3">
          <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Head Text</label>
          <textarea 
           id='head_text'
           name='head_text'
           value={formData?.head_text}
           onChange={handleChange}
          className="form-control" 
          style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
          ></textarea>
        </div>


      <div className="mb-3">
          <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Head Image</label>
          <input
           id='head_image'
           name='head_image'
            type="file"
            accept=".png, .jpg, .jpeg, .gif"
            onChange={handleFileChange}
            className="form-control"
          
           style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
            />
        </div>

        
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button bg-success bg-opacity-50 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
              Header colors
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
            <div className="accordion-body">
              <div className='d-flex flex-wrap justify-content-around'>
                
                  <div className="mb-3">
                      <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title color</label>
                      <input
                        id='head_title_color'
                        name='head_title_color'
                      type="color" 
                      value={formData?.head_title_color}
                      onChange={handleChange}
                      className="form-control form-control-color"
                      style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                        />

                    </div>
                  <div className="mb-3">
                      <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text color</label>
                      <input
                        id='head_text_color'
                        name='head_text_color'
                      type="color" 
                      value={formData?.head_text_color}
                      onChange={handleChange}
                      className="form-control form-control-color"
                      style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                        />

                    </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  {/* Body Section */}
  <div className="accordion-item">
      <h2 className="accordion-header">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          Body Section
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
        <div className="accordion-body">

          {/* main background color */}
          <div className="mb-3">
            <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Background Color</label>
            <input
              id='container_bg_color'
              name='container_bg_color'
            type="color" 
            value={formData?.container_bg_color}
            onChange={handleChange}
            className="form-control form-control-color"
            style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
              />

          </div>

          {/* Main Section */}
          <div className="accordion-item">
            <h4 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mainSection" aria-expanded="false" aria-controls="mainSection">
                Main Section
              </button>
            </h4>
            <div id="mainSection" className="accordion-collapse collapse">
              <div className="accordion-body">
                <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id='main_title'
                    name='main_title'
                    value={formData.main_title}
                    onChange={handleChange}
                    style={{ borderRadius: '5px', border: '1px solid #ccc', color: formData.main_title_color }} 
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                  <textarea
                    id='main_text'
                    name='main_text'
                    value={formData.main_text}
                    onChange={handleChange}
                    className="form-control"
                    style={{ borderRadius: '5px', border: '1px solid #ccc', color: formData.main_text_color }} 
                  ></textarea>
                </div>

                {/* main colors Section */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mainSection-colors" aria-expanded="false" aria-controls="mainSection-colors">
                      colors
                    </button>
                  </h2>
                  <div id="mainSection-colors" className="accordion-collapse collapse">
                    <div className="accordion-body d-flex flex-wrap justify-content-around">
                      <div className="mb-3">
                        <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>title </label>
                        <input
                        id='main_title_color'
                        name='main_title_color'
                        type="color" 
                        value={formData?.main_title_color}
                        onChange={handleChange}
                        className="form-control form-control-color"
                        style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                        
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>text</label>
                        <input
                        id='main_text_color'
                        name='main_text_color'
                        type="color" 
                        value={formData?.main_text_color}
                        onChange={handleChange}
                        className="form-control form-control-color"
                        style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                        
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* section 1 */}
          <div className="accordion-item">
            <h4 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#section1" aria-expanded="true" aria-controls="section1">
              Section 1
              </button>
            </h4>
            <div id="section1" className="accordion-collapse collapse show">
              <div className="accordion-body">
                
              <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                  <input
                  type="text" 
                  className="form-control" 
                  id='sub1_title'
                  name='sub1_title'
                  value={formData?.sub1_title}
                  onChange={handleChange}
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                    />
                </div>

                <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                  <textarea 
                  id='sub1_text'
                  name='sub1_text'
                  value={formData?.sub1_text}
                  onChange={handleChange}
                  className="form-control" 
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                  ></textarea>
                </div>


              <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Image</label>
                  <input
                  id='sub1_image'
                  name='sub1_image'
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={handleFileChange}
                    className="form-control"
                  
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                    />
                </div>

                
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button bg-success bg-opacity-50 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#section1-colors" aria-expanded="false" aria-controls="section1-colors">
                      colors
                    </button>
                  </h2>
                  <div id="section1-colors" className="accordion-collapse collapse">
                    <div className="accordion-body">
                      <div className='d-flex flex-wrap justify-content-around'>
                        
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                              <input
                                id='section1_text_color'
                                name='section1_text_color'
                              type="color" 
                              value={formData?.section1_text_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                              <input
                                id='section1_text_color'
                                name='section1_text_color'
                              type="color" 
                              value={formData?.section1_text_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* section 2 */}
          <div className="accordion-item">
            <h4 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#section2" aria-expanded="true" aria-controls="section2">
              Section 2
              </button>
            </h4>
            <div id="section2" className="accordion-collapse collapse show">
              <div className="accordion-body">
                
              <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                  <input
                  type="text" 
                  className="form-control" 
                  id='sub2_title'
                  name='sub2_title'
                  value={formData?.sub2_title}
                  onChange={handleChange}
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                    />
                </div>

                <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                  <textarea 
                  id='sub2_text'
                  name='sub2_text'
                  value={formData?.sub2_text}
                  onChange={handleChange}
                  className="form-control" 
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                  ></textarea>
                </div>


              <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Image</label>
                  <input
                  id='sub2_image'
                  name='sub2_image'
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={handleFileChange}
                    className="form-control"
                  
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                    />
                </div>

                
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button bg-success bg-opacity-50 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#section2-colors" aria-expanded="false" aria-controls="section2-colors">
                      colors
                    </button>
                  </h2>
                  <div id="section2-colors" className="accordion-collapse collapse">
                    <div className="accordion-body">
                      <div className='d-flex flex-wrap justify-content-around'>
                        
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                              <input
                                id='section2_title_color'
                                name='section2_title_color'
                              type="color" 
                              value={formData?.section2_title_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                              <input
                                id='section2_text_color'
                                name='section2_text_color'
                              type="color" 
                              value={formData?.section2_text_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          
          {/* section 3 */}
          <div className="accordion-item">
            <h4 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#section3" aria-expanded="true" aria-controls="section3">
              Section 3
              </button>
            </h4>
            <div id="section3" className="accordion-collapse collapse show">
              <div className="accordion-body">
                
              <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                  <input
                  type="text" 
                  className="form-control" 
                  id='sub3_title'
                  name='sub3_title'
                  value={formData?.sub3_title}
                  onChange={handleChange}
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                    />
                </div>

                <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                  <textarea 
                  id='sub3_text'
                  name='sub3_text'
                  value={formData?.sub3_text}
                  onChange={handleChange}
                  className="form-control" 
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                  ></textarea>
                </div>


              <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Image</label>
                  <input
                  id='sub3_image'
                  name='sub3_image'
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={handleFileChange}
                    className="form-control"
                  
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                    />
                </div>

                
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button bg-success bg-opacity-50 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#section3=colors" aria-expanded="false" aria-controls="section3=colors">
                      colors
                    </button>
                  </h2>
                  <div id="section3=colors" className="accordion-collapse collapse">
                    <div className="accordion-body">
                      <div className='d-flex flex-wrap justify-content-around'>
                        
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Title</label>
                              <input
                                id='section3_title_color'
                                name='section3_title_color'
                              type="color" 
                              value={formData?.section3_title_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                              <input
                                id='section3_text_color'
                                name='section3_text_color'
                              type="color" 
                              value={formData?.section3_text_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="accordion-item">
            <h4 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#buttonsSection" aria-expanded="true" aria-controls="buttonsSection">
              Buttons
              </button>
            </h4>
            <div id="buttonsSection" className="accordion-collapse collapse show">
              <div className="accordion-body">
                
              <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>View More</label>
                  <input
                  type="text" 
                  className="form-control" 
                  id='view_more_link'
                  name='view_more_link'
                  value={formData?.view_more_link}
                  onChange={handleChange}
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                    />
                </div>
              <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Website btn</label>
                  <input
                  type="text" 
                  className="form-control" 
                  id='website'
                  name='website'
                  value={process.env.REACT_APP_WEBSITE_LINK}
                  // onChange={handleChange}
                  disabled
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                    />
                </div>


                
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button bg-success bg-opacity-50 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBtnColors" aria-expanded="false" aria-controls="collapseBtnColors">
                      colors
                    </button>
                  </h2>
                  <div id="collapseBtnColors" className="accordion-collapse collapse">
                    <div className="accordion-body">
                      <div className='d-flex flex-wrap justify-content-around'>
                        
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Background</label>
                              <input
                                id='btn_bg_color'
                                name='btn_bg_color'
                              type="color" 
                              value={formData?.btn_bg_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
                              <input
                                id='btn_text_color'
                                name='btn_text_color'
                              type="color" 
                              value={formData?.btn_text_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                          <div className="mb-3">
                              <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Border</label>
                              <input
                                id='btn_bd_color'
                                name='btn_bd_color'
                              type="color" 
                              value={formData?.btn_bd_color}
                              onChange={handleChange}
                              className="form-control form-control-color"
                              style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
                                />

                            </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  {/* footer section  */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#footer" aria-expanded="true" aria-controls="footer">
      Footer 
      </button>
    </h2>
    <div id="footer" className="accordion-collapse collapse ">
      <div className="accordion-body d-flex flex-wrap justify-content-around">
        
      <div className="mb-3">
          <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Border Top</label>
          <input
          type="color" 
          id='footer_top_bd_color'
          name='footer_top_bd_color'
          value={formData?.footer_top_bd_color}
          onChange={handleChange}
          className="form-control form-control-color"
          style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
            />
      </div>
      <div className="mb-3">
          <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Background</label>
          <input
          type="color" 
          id='footer_bg_color'
          name='footer_bg_color'
          value={formData?.footer_bg_color}
          onChange={handleChange}
          className="form-control form-control-color"
          style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
            />
      </div>
      <div className="mb-3">
          <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Text</label>
          <input
          type="color" 
          id='footer_text_color'
          name='footer_text_color'
          value={formData?.footer_text_color}
          onChange={handleChange}
          className="form-control form-control-color"
          style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
            />
      </div>
      <div className="mb-3">
          <label className="form-label" style={{ fontWeight: 'bold', color: '#555' }}>Links</label>
          <input
          type="color" 
          id='footer_link_color'
          name='footer_link_color'
          value={formData?.footer_link_color}
          onChange={handleChange}
          className="form-control form-control-color"
          style={{ borderRadius: '5px', border: '1px solid #ccc' }} 
            />
      </div>


        

      </div>
    </div>
  </div>

</div>


<div className='my-3 d-flex flex-wrap justify-content-around gap-1'>


  <button 
    type="submit" 
    disabled={subjectExists}
    className='btn btn-success  px-4'
    style={{ cursor: 'pointer' }}>
    Save 
  </button>
  <button type="button" 
    onClick={handleDiscard}
    className='btn btn-warning  px-4'
    style={{ cursor: 'pointer' }}>
    Discard 
  </button>
  <button type="button" 
    onClick={handleDiscardColors}
    className='btn btn-warning  px-4'
    style={{ cursor: 'pointer' }}>
    Reset colors 
  </button>
  <button type="button" 
    onClick={navigateBack}
    className='btn btn-danger  px-4'
    style={{ cursor: 'pointer' }}>
    Back 
  </button>

</div>


</form>
</div>
  )
}
