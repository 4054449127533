import React, { useContext } from 'react'
import { AiOutlineUser, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { AuthContext } from '../context/AuthContext';

export default function PersonalDetails() {
   const {currentUser} = useContext(AuthContext)

   const handleInputChange = () => {

  };

  return (
   <div style={{ backgroundColor: 'lightgreen', borderRadius: '10px', padding: '20px', marginBottom: '30px' }}>
   <h3 style={{ color: 'darkgreen' }}>Personal Details</h3>
   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
       <AiOutlineUser size={30} color="green" />
       <input
           type="text"
           name="name"
           value={currentUser?.name}
           onChange={handleInputChange}
           style={{
               marginLeft: '10px',
               padding: '10px',
               width: '100%',
               borderRadius: '5px',
               border: '1px solid #ccc',
               backgroundColor: '#f0f8f5',
           }}
           placeholder="Full Name"
           readOnly
       />
   </div>
   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
       <AiOutlineMail size={30} color="green" />
       <input
           type="email"
           name="email"
           value={currentUser?.email}
           onChange={handleInputChange}
           style={{
               marginLeft: '10px',
               padding: '10px',
               width: '100%',
               borderRadius: '5px',
               border: '1px solid #ccc',
               backgroundColor: '#f0f8f5',
           }}
           placeholder="Email Address"
           readOnly
       />
   </div>
   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
       <AiOutlinePhone size={30} color="green" />
       <input
           type="tel"
           name="phone"
           value={currentUser?.phone}
           onChange={handleInputChange}
           style={{
               marginLeft: '10px',
               padding: '10px',
               width: '100%',
               borderRadius: '5px',
               border: '1px solid #ccc',
               backgroundColor: '#f0f8f5',
           }}
           placeholder="Phone Number"
           readOnly
       />
   </div>
</div>
  )
}
