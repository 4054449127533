import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export default function Dashboard() {
  const theme = useTheme(); // Access theme for colors and styles

  return (
    <div>
      <Typography style={{ textAlign: 'center', marginBottom: '30px' }}>
        Browse through our guides and tutorials for a deeper understanding of our platform.
      </Typography>

      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        {[
          {
            title: 'Getting Started Guide',
            description: 'A comprehensive guide to help you get started with setting up your email campaigns.',
            link: '/docs/getting-started',
          },
          {
            title: 'API Integration',
            description: 'Step-by-step instructions to integrate our platform with your website or custom application.',
            link: '/docs/api-integration',
          },
          {
            title: 'Advanced Tracking Features',
            description: 'Learn how to track your emails with advanced analytics tools and improve your campaign performance.',
            link: '/docs/tracking-features',
          },
          {
            title: 'Best Practices for Email Campaigns',
            description: 'Discover the best practices for designing, sending, and optimizing your email campaigns.',
            link: '/docs/best-practices',
          },
        ].map((doc, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
            whileHover={{ scale: 1.05 }}
            style={{
              flex: '1',
              minWidth: '220px',
              margin: '10px',
            }}
          >
            <Paper
              elevation={2}
              style={{
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                background: '#d4f5d4',
              }}
            >
              <Typography variant="h6">{doc.title}</Typography>
              <Typography>{doc.description}</Typography>
              <Link
                to={doc.link}
                style={{
                  color: theme.palette.primary.main, // Access the theme's primary color
                  textDecoration: 'underline',
                }}
              >
                Read More
              </Link>
            </Paper>
          </motion.div>
        ))}
      </Box>
    </div>
  );
}
