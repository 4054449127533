import React from 'react';
import { MdClose } from 'react-icons/md';
import ChangePasswordForm from '../profile/ChangePasswordForm'

const ChangePassword = () => {
 
  const handleBack = () => {
    window.history.back()
  };

  return (
    <div className="container-fluid" style={{
      backgroundColor: 'rgb(144,238,144,0.2)', 
      padding: '40px',
      borderRadius: '10px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      maxWidth: '600px',
      width: '100%',
      margin: '5rem auto', // Add margin to not touch the top
      border: '1px solid #e0e0e0',
      position:'relative',
    }}>
      <button onClick={handleBack} className='' style={{
    background: 'none',
    border: 'none',
    // color: '#ffffff',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    // alignItems: 'center',
    marginBottom: '20px',
    position:'absolute',
    top:5,
    right:5,
  }}>
        <MdClose color='red' size={30} />
      </button>
      <ChangePasswordForm/>
    </div>
  );
};


export default ChangePassword;
