import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AiOutlinePlus, AiOutlineArrowLeft } from 'react-icons/ai'; // Import the Add and Back icons
import { TemplateOneContext } from './TemplateOneContext';
import LoadingComponent from '../../components/LoadingComponent';

export default function Instances() {
  const navigate = useNavigate();
  const { instances, loading } = useContext(TemplateOneContext);

  // Handler for adding a new campaign
  const handleAddNewCampaign = () => {
    navigate('/templates/1/templates/new'); // Adjust URL if needed
  };

  // Handler for navigating back to templates
  const handleBack = () => {
    navigate('/templates');
  };

  return (
    <div className="container-fluid py-4" style={{ backgroundColor: 'rgba(1, 50, 32, 0.0)', minHeight: '100%' }}>
      <h2 className="text-center mb-4" style={{ color: 'green', fontFamily: 'junge' }}>
        Template 1 Instances
      </h2>

      {/* Add New Campaign and Back Buttons */}
      <div className="d-flex justify-content-start flex-nowrap mb-4 gap-3"> {/* Added gap-3 for spacing between buttons */}
        <button
          className="btn btn-secondary shadow-lg d-flex align-items-center"
          style={{ backgroundColor: 'grey', borderColor: 'darkgrey' }}
          onClick={handleBack}
        >
          <AiOutlineArrowLeft className="me-2" size={20} /> {/* Back icon */}
          Back to Templates
        </button>

        <button
          className="btn btn-success shadow-lg d-flex align-items-center"
          style={{ backgroundColor: 'green', borderColor: 'darkgreen' }}
          onClick={handleAddNewCampaign}
        >
          <AiOutlinePlus className="me-2" size={20} /> {/* Add icon */}
          Add New Campaign
        </button>
      </div>

      {loading ? (
        <LoadingComponent loadingText={'Loading Campaigns ....'} />
      ) : (
        <motion.div
          className="row"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2.5 }}
        >
          {Array.isArray(instances) && instances.length > 0 && (
            instances.map((instance, index) => (
              <motion.div
                key={instance.id}
                className="col-lg-4 col-md-4 col-sm-6 mb-4 px-4"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.2 * index }}
              >
                <div
                  className="card border-secondary shadow-lg"
                  style={{ backgroundColor: 'rgba(175, 238, 238, 0.8)', overflow: 'hidden', height: '20em' }}
                >
                  <motion.img
                    src={instance.image}
                    onClick={() => navigate(`/templates/1/templates/${instance?.id}`)}
                    className="card-img-top img-fluid"
                    style={{ height: '100%' }}
                    alt={instance.subject}
                    initial={{ scale: 1.2 }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  />
                  <div
                    className="card-footer"
                    style={{ position: 'absolute', bottom: 0, width: '100%', backgroundColor: 'rgb(5,15,27,0.68)' }}
                  >
                    <small className="card-title text-center text-light fst-italic">
                      {instance.subject}
                    </small>
                    <br />
                    <small className="card-title text-center text-light fst-italic">
                      Created: {instance.created_at}
                    </small>
                  </div>
                </div>
              </motion.div>
            ))
          )}
        </motion.div>
      )}
    </div>
  );
}
