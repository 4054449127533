import React, { useContext, useState } from 'react';
import backgroundImage from '../images/lion.png'; // Import your background image 
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Swal from 'sweetalert2';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { motion } from 'framer-motion';
import LoadingComponent from '../components/LoadingComponent';
import * as envVars from '../utils/EnvVariables';


const Login = () => {
  const { login,loading,setLoading } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const isValidEmail = (email) => {
    if (email === envVars.EMAIL){
      return true
    }else{
      return false
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (!isValidEmail(email)) {
      
      Swal.fire({
        title:'Invalid email address',
        showConfirmButton:false,
        timer:4000,
        icon:'warning',
        color:'white',
        background:'orange',
        toast:true,
        position:'top'
       });
      return;
    }
    

    login({email, password});
    setLoading(true)
  }
  if(loading){
    return(
      <LoadingComponent loadingText={'Authenticating details ...'} />
    )
  }

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.div style={{
        backgroundColor: 'rgb(5,15,27,0.8)',
        padding: '40px',
        borderRadius: '10px',
        border: '1px solid #ccc',
        maxWidth: '400px',
        width: '80%'
      }}
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        transition={{ duration: 1.5 }}>
        <h2 style={{ fontFamily: 'Arial, sans-serif', color: 'lightgreen', marginBottom: '30px', textAlign: 'center' }}>Login</h2>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '20px' }}>
            <input
              required
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              style={{
                borderRadius: '5px',
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc'
              }}
            />
          </div>
          <div style={{ position: 'relative', marginBottom: '20px' }}>
            <input
              required
              id='password'
              name='password'
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              style={{
                borderRadius: '5px',
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc'
              }}
            />
            <div
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
              }}
            >
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm"
            style={{
              width: '100%',
              borderRadius: '5px',
              padding: '10px',
              fontSize: '1rem',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none'
            }}
          >
            Submit
          </button>
        </form>
        <p style={{ color: 'wheat', marginTop: '20px', fontSize: '0.9rem', textAlign: 'center' }}>
          Forgot password? <Link to={'/auth/password/reset'}>Reset here</Link>
        </p>
      </motion.div>
    </div>
  );
};

export default Login;