import React from 'react';
import { motion } from 'framer-motion';
import { AiOutlinePhone, AiOutlineMail, AiOutlineFileText, AiOutlineQuestionCircle, AiOutlineSetting } from 'react-icons/ai';
import { createTheme, ThemeProvider, Box, Typography, Paper } from '@mui/material';
import Faqs from './Faqs';
import {useNavigate} from 'react-router-dom'
import Dashboard from '../documentation/Dashboard';
import FurtherAssistance from './FurtherAssistance';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4caf50', // Main green color
        },
        background: {
            default: '#eafaf1', // Light green background
        },
    },
});

const SupportAndResources = () => {
    const navigate = useNavigate()
    return (
        <ThemeProvider theme={theme}>
            <motion.div
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }} 
                transition={{ duration: 0.8 }}
                style={{
                    fontFamily: 'Arial, sans-serif',
                    padding: '20px',
                    minHeight: '100vh',
                }}
            >
                <motion.h1
                    initial={{ y: -50, opacity: 0 }} 
                    animate={{ y: 0, opacity: 1 }} 
                    transition={{ duration: 0.8 }}
                    style={{ textAlign: 'center', marginBottom: '40px', color: theme.palette.primary.main }}
                >
                    Support & Resources
                </motion.h1>

                <motion.p
                    initial={{ x: -50, opacity: 0 }} 
                    animate={{ x: 0, opacity: 1 }} 
                    transition={{ duration: 0.8 }}
                    style={{ textAlign: 'center', fontSize: '1.1rem', marginBottom: '30px' }}
                >
                    We’re here to help! Browse our resources or reach out to our customer support team for assistance.
                </motion.p>

                {/* Support Options Section */}
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" marginBottom="40px">
                    {[
                        { title: 'Customer Support', description: 'Reach out to us via phone or email for any immediate assistance or questions.', icon: <AiOutlinePhone />, contact: 'Phone: +254 725 499 649' },
                        { title: 'Email Support', description: 'Have a query? Email us and we’ll get back to you within 24 hours.', icon: <AiOutlineMail />, contact: 'Email: info@safarinetics.com' },
                        { title: 'FAQs', description: 'Explore answers to commonly asked questions about our platform.', icon: <AiOutlineQuestionCircle />, contact: 'Browse FAQs',link:"/faqs" },
                        { title: 'Documentation', description: 'View detailed guides, step-by-step tutorials, and integration tips.', icon: <AiOutlineFileText />, contact: 'View Documentation',link:'/docs' },
                        { title: 'Account Settings', description: 'Manage your account settings including subscription, password, and profile.', icon: <AiOutlineSetting />, contact: 'Go to Settings', link:'/settings'}
                    ].map((supportItem, index) => (
                        <motion.div 
                            key={index}
                            initial={{ opacity: 0 }} 
                            whileInView={{ opacity: 1 }} 
                            transition={{ duration: 0.6, delay: index * 0.2 }}
                            whileHover={{ scale: 1.05 }}
                            style={{
                                margin: '10px',
                                flex: '1',
                                minWidth: '260px',
                            }}
                        >
                            <Paper elevation={2} style={{ padding: '20px', borderRadius: '10px', textAlign: 'center', background: '#d4f5d4' }}>
                                <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
                                    {supportItem.icon} {supportItem.title}
                                </Typography>
                                <Typography>{supportItem.description}</Typography>
                                {supportItem?.link ? (
                                <Typography
                                style={{cursor:'pointer'}}
                                onClick={() => navigate(supportItem?.link)}
                                ><strong>{supportItem.contact}</strong></Typography>):
                                (
                                    <Typography><strong>{supportItem.contact}</strong></Typography>)}
                            </Paper>
                        </motion.div>
                    ))}
                </Box>

                {/* FAQ Section */}
                <Faqs />
                {/* Documentation Section */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    style={{
                        marginBottom: '40px',
                    }}
                    >
                    <Paper elevation={3} style={{ padding: '30px', borderRadius: '10px', backgroundColor: '#c4e7c4' }}>
                        <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px', color: theme.palette.primary.main }}>
                        Documentation
                        </Typography>
                        <Dashboard />
                    </Paper>
                </motion.div>

                {/* Contact Section */}
                <FurtherAssistance theme={theme}/>
                
            </motion.div>
        </ThemeProvider>
    );
};

export default SupportAndResources;
