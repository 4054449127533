import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageViewer from '../../utils/ImageViewer';
import { TemplateTwoContext } from './TemplateTwoContext';

const Preview = ({ formData }) => {
  const { instance } = useContext(TemplateTwoContext);

  const images = [
    formData?.head_image_url || instance?.head_image,
    formData?.sub1_image_url || instance?.sub1_image,
    formData?.sub2_image_url || instance?.sub2_image,
    formData?.sub3_image_url || instance?.sub3_image,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [scale, setScale] = useState(1);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setScale(1);
    setIsOpen(true);
  };

  return (
    <div style={{ padding: '0', width: '100%', maxWidth: '600px', margin: 'auto', backgroundColor: formData?.container_bg_color || instance?.container_bg_color, boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '2px', overflow: 'hidden' }}>
      <table width="100%">
        <tbody>
          <tr>
            <td>
              {/* <img src={pixelUrl} alt="pixel" style={{ display: 'none' }} /> */}
              <div style={{ width: '100%', backgroundImage: `url(${formData?.head_image_url || instance?.head_image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                <div style={{ textAlign: 'center', padding: '30px 20px' }}>
                  <img src={process.env.REACT_APP_LOGO} alt="logo" style={{ height: '100px' }} />
                  <h1 style={{ fontSize: '2em', fontWeight: 'bold', color: formData?.head_title_color || instance?.head_title_color }}>{formData?.head_title || instance?.head_title}</h1>
                  <p style={{ fontSize: '1.3em', color: formData?.head_text_color || instance?.head_text_color }}>{formData?.head_text || instance?.head_text}</p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ padding: '20px 30px' }}>
              <h2 style={{ color: formData?.main_title_color || instance?.main_title_color, fontSize: '2em' }}>{formData?.main_title || instance?.main_title}</h2>
              <p style={{ color: formData?.main_text_color || instance?.main_text_color }}><span>Hello,</span><br />{formData?.main_text || instance?.main_text}</p>
              {[
                { title: formData?.sub1_title || instance?.sub1_title, image: formData?.sub1_image_url || instance?.sub1_image, text: formData?.sub1_text || instance?.sub1_text },
                { title: formData?.sub2_title || instance?.sub2_title, image: formData?.sub2_image_url || instance?.sub2_image, text: formData?.sub2_text || instance?.sub2_text },
                { title: formData?.sub3_title || instance?.sub3_title, image: formData?.sub3_image_url || instance?.sub3_image, text: formData?.sub3_text || instance?.sub3_text }
              ].map((sub, index) => (
                <div key={index} style={{ textAlign: 'center', marginBottom: '20px' }}>
                  <h2 
                    style={{ 
                      color: formData?.[`section${index + 1}_title_color`] || instance?.[`section${index + 1}_title_color`], 
                      fontSize: '1.8em' 
                    }}
                  >
                    {sub.title}
                  </h2>

              
                  <img
                    onClick={() => openModal(index + 1)}
                    src={sub.image}
                    alt="Tour"
                    style={{ width: '100%', borderRadius: '2px', marginBottom: '20px' }}
                  />
                  <ImageViewer
                    images={images}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    scale={scale}
                    setScale={setScale}
                  />
                  <p style={{ color: formData?.[`section${index + 1}_text_color`] || instance?.[`section${index + 1}_text_color`] }}>{sub.text}</p>
                </div>
              ))}
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <a className='mx-1' href={formData?.view_more_link || instance?.view_more_link} style={{ display: 'inline-block', padding: '10px 40px', backgroundColor: formData?.btn_bg_color || instance?.btn_bg_color, color: formData?.btn_text_color || instance?.btn_text_color, textDecoration: 'none', border: `1px solid ${formData?.btn_bd_color || instance?.btn_bd_color}`, borderRadius: '5px', fontSize: '16px', fontWeight: 'bold' }}>View More</a>
                <a className='mx-1' href={process.env.REACT_APP_WEBSITE_LINK} style={{ display: 'inline-block', padding: '10px 40px', backgroundColor: formData?.btn_bg_color || instance?.btn_bg_color, color: formData?.btn_text_color || instance?.btn_text_color, textDecoration: 'none', border: `1px solid ${formData?.btn_bd_color || instance?.btn_bd_color}`, borderRadius: '5px', fontSize: '16px', fontWeight: 'bold' }}>Go To Website</a>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ backgroundColor: formData?.footer_bg_color || instance?.footer_bg_color, color: formData?.footer_text_color || instance?.footer_text_color, textAlign: 'center', padding: '20px', borderTop: `2px solid ${formData?.footer_top_bd_color || instance?.footer_top_bd_color}`, fontSize: '0.9em', width: '100%' }}>
              <table style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                <tbody>
                  <tr>
                    <td>
                      <div style={{ margin: '10px 0', textAlign: 'center' }}>
                        <a href={process.env.REACT_APP_FACEBOOK_LINK} style={{ margin: '0 5px', display: 'inline-block' }}>
                          <img src="https://img.icons8.com/ios-filled/50/1877F2/facebook-new.png" alt="Facebook" style={{ width: '30px', height: '30px', border: 'none', transition: 'transform 0.3s ease' }} />
                        </a>
                        <a href={process.env.REACT_APP_X_LINK} style={{ margin: '0 5px', display: 'inline-block' }}>
                          <img src="https://ik.imagekit.io/4rfgtafuc0/social%20media/x%20logo%20(1).png?updatedAt=1726049165622" alt="X" style={{ width: '30px', height: '30px', border: 'none', transition: 'transform 0.3s ease' }} />
                        </a>
                        <a href={process.env.REACT_APP_INSTAGRAM_LINK} style={{ margin: '0 5px', display: 'inline-block' }}>
                          <img src="https://www.pngmart.com/files/21/Instagram-PNG-Isolated-File.png" alt="Instagram" style={{ width: '30px', height: '30px', border: 'none', transition: 'transform 0.3s ease' }} />
                        </a>
                        <a href={process.env.REACT_APP_LINKEDIN_LINK} style={{ margin: '0 5px', display: 'inline-block' }}>
                          <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" style={{ width: '30px', height: '30px', border: 'none', transition: 'transform 0.3s ease' }} />
                        </a>
                        <a href={process.env.REACT_APP_YOUTUBE_LINK} style={{ margin: '0 5px', display: 'inline-block' }}>
                          <img src="https://img.icons8.com/ios-filled/50/ff0000/youtube-play.png" alt="YouTube" style={{ width: '30px', height: '30px', border: 'none', transition: 'transform 0.3s ease' }} />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center', color: formData?.footer_text_color || instance?.footer_text_color }}>
                      <p>Email: <a href={`mailto:${process.env.REACT_APP_EMAIL}`} style={{ color: formData?.footer_text_color || instance?.footer_text_color }}>{process.env.REACT_APP_EMAIL}</a></p>
                      <p>&copy; 2024 {process.env.REACT_APP_COMPANY_NAME}. All rights reserved.</p>

                      <p>
                        <Link to={instance?.unsubscribe} style={{ color: instance?.footer_link_color }}>Unsubscribe</Link>
                        <span className='mx-1'>|</span>
                        <Link to='/pages/privacy-policy'  style={{ color: instance?.footer_link_color }}>Privacy Policy</Link>
                    </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Preview;
