import React, { useContext, useState } from 'react';
import { EmailContext } from '../context/EmailContext';
import AddEmail from './AddEmail';
import Swal from 'sweetalert2';
import AddBulkEmails from './AddBulkEmails';
import DownloadEmails from './DownloadEmails';
import RefreshEmails from './RefreshEmails';
import LoadingComponent from '../components/LoadingComponent';
import { 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Box, 
  Grid, 
  TablePagination 
} from '@mui/material';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const Emails = () => {
  const { emails, unsubscribeEmail, loading, subscribedCount,unsubscribedCount } = useContext(EmailContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleUnsubscribeEmail = (email) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unsubscribe!",
      background: 'rgba(255, 255, 255, 0.9)',
    }).then((result) => {
      if (result.isConfirmed) {
        unsubscribeEmail(email);
      }
    });
  };


  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
          <LoadingComponent loadingText={'Loading emails ....'}/>
    );
  }

  return (
    <Box sx={{ padding: '20px' }}>
      {/* Header */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ color: '#28a745', fontWeight: 'bold', fontFamily: 'Junge', letterSpacing: 1 }}
      >
        Subscribers Management
      </Typography>

      {/* Action Buttons and Stats */}
      <Grid container spacing={2} justifyContent="space-between" sx={{ marginBottom: '20px' }}>
        {/* Left Buttons */}
        <Grid item xs={12} sm={6} md={6}>
          <Grid container spacing={2}>
            <Grid item>
              <AddEmail />
            </Grid>
            <Grid item>
              <AddBulkEmails />
            </Grid>
            <Grid item>
              <DownloadEmails emails={emails} />
            </Grid>
            <Grid item>
              <RefreshEmails />
            </Grid>
          </Grid>
        </Grid>

        {/* Right Stats */}
        <Grid item xs={12} sm={6} md={6}>
          <Grid container spacing={2} justifyContent="flex-center">
            <Grid item>
              <Typography variant="body1">
                <strong>Subscribed:</strong> 
                <span style={{ fontFamily: "fantasy", fontWeight: 'bold', color: "green", fontSize: '1.5rem' }}>
                  {subscribedCount}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <strong>Unsubscribed:</strong> 
                <span style={{ fontFamily: "fantasy", fontWeight: 'bold', color: "red", fontSize: '1.5rem' }}>
                  {unsubscribedCount}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <strong className='text-success'>All:</strong> 
                <span style={{ fontFamily: "fantasy", fontWeight: 'bold', color: "orange", fontSize: '1.5rem' }}>
                  {emails?.length}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Email Table */}
      <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ backgroundColor: '#28a745', color: 'white', fontWeight: 'bold' }}>#</TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#28a745', color: 'white', fontWeight: 'bold' }}>Email</TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#28a745', color: 'white', fontWeight: 'bold' }}>Subscribed</TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#28a745', color: 'white', fontWeight: 'bold' }}>Added</TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#28a745', color: 'white', fontWeight: 'bold' }}>Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emails && emails.length > 0 ? (
              emails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((email, index) => (
                  <TableRow hover key={index}>
                    <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell align="center">{email.email}</TableCell>
                    <TableCell align="center">
                      {email.subscribed ? (
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          startIcon={<FaCheckCircle />}
                          onClick={() => handleUnsubscribeEmail(email.email)}
                          sx={{
                            ':hover': { backgroundColor: '#218838' },
                          }}
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          startIcon={<FaTimesCircle />}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">{email.added_at}</TableCell>
                    <TableCell align="center">{email.updated_at}</TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="body1" color="warning">
                    No emails are currently uploaded to the system.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          style={{background:'lightgreen'}}
          component="div"
          count={emails.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default Emails;
