import React, { useState } from 'react';
import { Container, Typography, Tabs, Tab, Box, Paper } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Profile from '../profile/Profile';
import Subscription from '../subscription/Subscription';
import SupportAndResources from '../support/SupportAndResources';

const theme = createTheme({
    palette: {
        primary: {
            main: '#388e3c', // Green theme color
        },
        secondary: {
            main: '#f5f5f5', // Light background
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h2: {
            fontWeight: 500,
            color: '#333',
        },
        h5: {
            fontWeight: 400,
        },
    },
});

function TabPanel({ children, value, index }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            style={{ paddingTop: '20px' }}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

const Setting = () => {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" sx={{ my: 5 }}>
                <Paper elevation={3} sx={{ p: 4, backgroundColor: 'rgb(144,238,144,0.4)' }}>
                    <Typography variant="h2" align="center" gutterBottom>
                        Settings
                    </Typography>

                    {/* Tabs */}
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        sx={{
                            '& .MuiTab-root': {
                                textTransform: 'none', // No uppercase
                                fontSize: '1rem',
                                fontWeight: 500,
                            },
                        }}
                    >
                        <Tab label="Profile" />
                        <Tab label="Subscription" />
                        <Tab label="Help & Support" />
                    </Tabs>

                    {/* Tab Panels */}
                    <TabPanel value={tabValue} index={0}>
                        <Profile />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Subscription />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <SupportAndResources />
                    </TabPanel>

                    
                </Paper>
            </Container>
        </ThemeProvider>
    );
};

export default Setting;
