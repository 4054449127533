import React, { createContext, useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/RequestsQueue'; // Import the request queue
import { LogContext } from '../../context/LogContext';
import {AuthContext} from '../../context/AuthContext';

export const TemplateOneContext = createContext();

export default function TemplateOneProvider({ children }) {
  const navigate = useNavigate();
  const authToken = localStorage.getItem('beshNewsletterAuthToken')
  const [instances, setInstances] = useState([]);
  const [temp1Change, setTemp1Change] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [instance, setInstance] =useState({})
  const {fetchLogs} = useContext(LogContext)
  const { loggedIn } = useContext(AuthContext);

  // ========================= FETCH TEMPLATE 1 INSTANCES ============================
  // Async function to fetch instances
  const fetchInstances = async () => {
    if (!authToken) return;

    setLoading(true);

    try {
      const response = await axiosInstance.requestWithQueue({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/template1/all`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setInstances(response.data);
    } catch (error) {
      console.error('Error fetching instances:', error);
    } finally {
      setLoading(false);
    }
  };

  // Call the async function inside useEffect
  useEffect(() => {
    fetchInstances();
  }, [temp1Change, authToken, loggedIn]);


  // ========================= ADD TEMPLATE 1 INSTANCE ============================
  const addTemp1Instance = async (formData) => {
    setLoading(true);

    try {
      const response = await axiosInstance.requestWithQueue({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/template1/add`,
        data: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        setTemp1Change(!temp1Change);
        navigate(`/templates/1/templates/${response.data.id}`);

        Swal.fire({
          icon: 'success',
          text: response.data.success,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat',
        });

        localStorage.removeItem('formData');
        sessionStorage.removeItem('imageFile');
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  // ========================= UPDATE TEMPLATE 1 INSTANCE ============================
  const UpdateTemp1Instance = async (instanceId, formData) => {
    setLoading(true);

    try {
      const response = await axiosInstance.requestWithQueue({
        method: 'PATCH',
        url: `${process.env.REACT_APP_SERVER_URL}/template1/${instanceId}/update`,
        data: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        setTemp1Change(!temp1Change);
        navigate(`/templates/1/templates/${instanceId}`)
        Swal.fire({
          icon: 'success',
          text: response.data.success,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat',
        });
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      setUpdate(false);
    }
  };

  // ========================= DELETE TEMPLATE 1 INSTANCE ============================
  const deleteTemp1Instance = async (instanceId) => {
    setLoading(true);

    try {
      const response = await axiosInstance.requestWithQueue({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SERVER_URL}/template1/${instanceId}/delete`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        setTemp1Change(!temp1Change);
        navigate(`/templates/1/templates`);

        Swal.fire({
          icon: 'success',
          text: response.data.success,
          position: 'top-end',
          timer: 4000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat',
        });
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 4000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  // ========================= SEND TEMPLATE 1 INSTANCE ============================


 // +++++++++++++ TEST ++++++++++++++++

async function testTemp1Instance(instanceId, email) {
  setLoading(true);

  try {
    const response = await axiosInstance.requestWithQueue({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_URL}/template1/${instanceId}/test`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data: { email },
    });

    if (response.data.success) {
      setTemp1Change(!temp1Change);
      fetchLogs()
      Swal.fire({
        icon: 'success',
        text: response.data.success,
        position: 'top-end',
        timer: 24000,
        toast: true,
        showConfirmButton: false,
        background: '#228B22',
        color: 'wheat',
      });
    } else {
      Swal.fire({
        icon: 'warning',
        text: response.data.error || response.data.msg,
        position: 'top-end',
        timer: 24000,
        toast: true,
        showConfirmButton: false,
        background: 'red',
        color: 'wheat',
      });
    }
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      icon: 'error',
      text: error.message || 'An error occurred',
      position: 'top-end',
      timer: 24000,
      toast: true,
      showConfirmButton: false,
      background: 'red',
      color: 'wheat',
    });
  } finally {
    setLoading(false);
  }
}
//++++++++++++++ END ++++++++++++++++



//++++++++++++++ SEND ++++++++++++++++



  const sendTemp1Instance = async (instanceId) => {
    setLoading(true);

    try {
      const response = await axiosInstance.requestWithQueue({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/template1/${instanceId}/send`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        fetchLogs()
        Swal.fire({
          icon: 'success',
          text: response.data.success,
          position: 'top-end',
          timer: 4000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat',
        });
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 4000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TemplateOneContext.Provider
      value={{
        update, setUpdate,
        instances,
        temp1Change,
        setTemp1Change,
        addTemp1Instance,
        UpdateTemp1Instance,
        deleteTemp1Instance,
        testTemp1Instance,
        sendTemp1Instance,
        loading, setLoading,
        fetchInstances,
        instance, setInstance,
      }}
    >
      {children}
    </TemplateOneContext.Provider>
  );
}
