import React, { useState, useContext } from 'react';
import ImageViewer from '../../utils/ImageViewer';
import { TemplateOneContext } from './TemplateOneContext';

const Preview = ({formData}) => {
  const { instance } = useContext(TemplateOneContext);

  const images = [instance?.image];
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [scale, setScale] = useState(1);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setScale(1);
    setIsOpen(true);
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "rgba(244, 244, 244, 0)",
        margin: 0,
        padding: 0,
        lineHeight: 1.6,
        color: formData?.text_color ||instance?.text_color || '#000000',
      }}
    >
      <table
        style={{
          width: "100%",
          maxWidth: "600px",
          margin: "0 auto",
          backgroundColor: formData?.body_bg_color || instance?.body_bg_color || 'rgba(152, 255, 152, 0.4)',
          borderRadius: "8px",
          overflow: "hidden",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          border: `0 solid ${formData?.btn_border_color || instance?.btn_border_color || '#333333'}`,
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                backgroundColor:  formData?.header_bg_color || instance?.header_bg_color || '#32CD32',
                textAlign: "center",
                padding: "30px 0",
                borderBottom: `3px solid ${formData?.header_bd_btm_color || instance?.header_bd_btm_color || '#333333'}`,
                maxHeight: "fit-content",
                minHeight: "120px",
                height: "auto",
              }}
            >
              <img
                src={process.env.REACT_APP_LOGO}
                alt="Logo"
                style={{ height: "100px", display: "block", margin: "0 auto" }}
              />
              <h1
                style={{
                  fontSize: "clamp(1.5em, 2vw, 2.5em)",
                  color: formData?.title_color || instance?.title_color || '#333333',
                  margin: "20px 0 10px 0",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                }}
              >
                {formData?.title || instance?.title}
              </h1>
            </td>
          </tr>

          <tr>
            <td style={{ padding: "20px", textAlign: "center" }}>
              {/* <img src={pixel_url} alt="pixel" style={{ display: "none" }} /> */}
              <p style={{ textAlign: "left", margin: 0 }}>Hello,</p>
              <ImageViewer 
                    images={images}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    scale={scale}
                    setScale={setScale}
                  />
              <img
              onClick={()=> openModal(currentImageIndex)}
                src={formData?.image_url || instance?.image}
                alt="Tour Image"
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  borderRadius: "1px",
                  marginTop: "10px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}
              />
              <p style={{ marginBottom: "20px", fontSize: "1.1em", textAlign: "center" }}>
                {formData?.text || instance?.text}{" "}
                <a
                  href={formData?.view_more_link || instance?.view_more_link}
                  className="view-more-link"
                  style={{ color: formData?.view_more_link_color || instance?.view_more_link_color || '#0000FF' }}
                >
                  View More
                </a>
                .
              </p>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <a
                  href={process.env.REACT_APP_WEBSITE_LINK}
                  style={{
                    display: "inline-block",
                    padding: "10px 40px",
                    margin: "10px 30px",
                    backgroundColor: formData?.btn_bg_color || instance?.btn_bg_color || '#32CD32',
                    color: formData?.btn_text_color || instance?.btn_text_color || '#808000',
                    textDecoration: "none",
                    border: `1px solid ${formData?.btn_border_color || instance?.btn_border_color || '#808000'}`,
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Go to Website
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: formData?.footer_bg_color || instance?.footer_bg_color || '#333333',
                color: formData?.footer_text_color || instance?.footer_text_color || '#FFFFFF',
                textAlign: "center",
                padding: "20px",
                borderTop: `1px solid ${formData?.footer_bd_top_color || instance?.footer_bd_top_color}`,
                fontSize: "0.9em",
              }}
            >
              <div style={{ margin: "20px 0", display: "inline-block" }}>
                <a href={process.env.REACT_APP_FACEBOOK_LINK} style={{ margin: "0 5px", display: "inline-block" }}>
                  <img
                    src="https://img.icons8.com/ios-filled/50/1877F2/facebook-new.png"
                    alt="Facebook"
                    style={{ width: "30px", height: "30px", border: "none" }}
                  />
                </a>
                <a href={process.env.REACT_APP_X_LINK} style={{ margin: "0 5px", display: "inline-block" }}>
                  <img
                    src="https://ik.imagekit.io/4rfgtafuc0/social%20media/x%20logo%20(1).png?updatedAt=1726049165622"
                    alt="X"
                    style={{ width: "30px", height: "30px", border: "none" }}
                  />
                </a>
                <a href={process.env.REACT_APP_INSTAGRAM_LINK} style={{ margin: "0 5px", display: "inline-block" }}>
                  <img
                    src="https://www.pngmart.com/files/21/Instagram-PNG-Isolated-File.png"
                    alt="Instagram"
                    style={{ width: "30px", height: "30px", border: "none" }}
                  />
                </a>
                <a href={process.env.REACT_APP_LINKEDIN_LINK} style={{ margin: "0 5px", display: "inline-block" }}>
                  <img
                    src="https://img.icons8.com/ios-filled/50/0072b1/linkedin.png"
                    alt="LinkedIn"
                    style={{ width: "30px", height: "30px", border: "none" }}
                  />
                </a>
                <a href={process.env.REACT_APP_YOUTUBE_LINK} style={{ margin: "0 5px", display: "inline-block" }}>
                  <img
                    src="https://img.icons8.com/ios-filled/50/ff0000/youtube-play.png"
                    alt="YouTube"
                    style={{ width: "30px", height: "30px", border: "none" }}
                  />
                </a>
              </div>

              <p style={{ margin: "10px 0", fontSize: "0.8em", lineHeight: 1.5 }}>
                &copy; 2024 {process.env.REACT_APP_COMPANY_NAME}. All rights reserved.
              </p>
              <p style={{ margin: "5px 0" }}>
                <a href={'#'} style={{ color: formData?.footer_link_color || instance?.footer_link_color || '#28a745', textDecoration: "none" }}>
                  Unsubscribe
                </a>{" "}
                |{" "}
                <a
                  href={'#'}
                  style={{ color: formData?.footer_link_color || instance?.footer_link_color || '#28a745', textDecoration: "none" }}
                >
                  Privacy Policy
                </a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Preview;
